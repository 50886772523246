@import '@Styles/variables';

.container {
  width: 40px;
  height: 40px;
  display: block;
  aspect-ratio: 1 / 1;
  .zoom {
    transform: scale(0.75);
    width: 100%;
    height: 100%;
  }
}

.skChase {
  width: 100%;
  height: 100%;
  position: relative;
  animation: skChase 2.5s infinite linear both;
}

.skChaseDot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: skChaseDot 2.0s infinite ease-in-out both;
}

.skChaseDot .before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: $purple-3;
  border-radius: 100%;
  animation: skChaseDot-before 2.0s infinite ease-in-out both;
}

.skChaseDot:nth-child(6) { opacity: 1;animation-delay: -1.1s; }
.skChaseDot:nth-child(5) { opacity: 0.95;animation-delay: -1.0s; }
.skChaseDot:nth-child(4) { opacity: 0.8;animation-delay: -0.9s; }
.skChaseDot:nth-child(3) { opacity: 0.85;animation-delay: -0.8s; }
.skChaseDot:nth-child(2) { opacity: 0.7;animation-delay: -0.7s; }
.skChaseDot:nth-child(1) { opacity: 0.75;animation-delay: -0.6s; }
.skChaseDot:nth-child(6) .before { transform: scale(1.0); animation-delay: -1.1s; }
.skChaseDot:nth-child(5) .before { transform: scale(0.85); animation-delay: -1.0s; }
.skChaseDot:nth-child(4) .before { transform: scale(0.70); animation-delay: -0.9s; }
.skChaseDot:nth-child(3) .before { transform: scale(0.65); animation-delay: -0.8s; }
.skChaseDot:nth-child(2) .before { transform: scale(0.50); animation-delay: -0.7s; }
.skChaseDot:nth-child(1) .before { transform: scale(0.45); animation-delay: -0.6s; }

@keyframes skChase {
  100% { transform: rotate(360deg); }
}

@keyframes skChaseDot {
  80%, 100% { transform: rotate(360deg); }
}

// @keyframes skChaseDot-before {
//   50% {
//     transform: scale(0.4);
//   } 100%, 0% {
//     transform: scale(1.0);
//   }
// }
