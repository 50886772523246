.loading-container {
  width: 100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-control {
  border-radius: 3px;
  color: $purple-3;
  font-weight: 500;
  text-transform: capitalize;
  .form-control {
    border-color: $purple-4;
    color: $purple-3;
    text-transform: capitalize;
    font-weight: 500;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;
    &:hover {
      border-color: $purple-3;
    }
  }

  option {
    font-weight: 500;
  }

  i {
    color: $purple-3;
  }
}