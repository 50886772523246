.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-justify {
  text-align: justify;
  text-align-last: left;
}

.text-overflow-clip {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}

.text-primary {
  color: var(--color-primary) !important;
}

.text-secondary {
  color: var(--color-secondary) !important;
}

.text-faded {
  color: var(--color-faded) !important;
}

.text-danger {
  color: var(--color-danger) !important;
}

.text-success {
  color: var(--color-success) !important;
}

.text-warning {
  color: var(--color-warning) !important;
}

.text-info {
  color: var(--color-info) !important;
}

.text-question {
  color: var(--color-question) !important;
}
