.popover {
  box-shadow: var(--popover-box-shadow);
  border: var(--popover-border);
  border-radius: var(--popover-border-radius);
  padding: var(--popover-padding);
  width: max-content;
  min-width: min-content;
  // max-width: calc(75vw - var(--spacing-2));
  display: block;
  word-wrap: break-word;
  background-color: #fff;
  z-index: var(--z-index-popover, 9999);
}