$font-rounded: 'Nunito', sans-serif;
$font-default: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-monospace: 'JetBrains Mono', monospace;

$sidebar-width: 156px;

$padding: 15px;

$shadow : 0 0 3px 2px rgba(#000, 0.1),
0 8px 8px 0px rgba(#000, 0.1) !important;
$box-shadow : 0px 4px 4px rgba(#000, 0.25);
$box-shadow-hover : 0px 4px 4px rgba(#000, 0.45);
$inset-shadow : inset 0px 4px 4px rgba(#000, 0.25);
$border-radius : 0.5em;
$form-control-height: 38px;

$purple-new: #9300D9;
$purple-new-hover: #550A90;

$purple: #834BE1;
$purple-1: #180133;
$purple-2: #492B7C;
$purple-3: #834BE1;
$purple-4: #B487FE;
$purple-5: #E2D1FF;
$purple-6: #F4EEFF;

$pink: #E14BDB;
$pink-1: #8D0C88;
$pink-2: #E14BDB;
$pink-3: #FF8FFB;

$orange: #ff7400;
$orange-1: #4D0D00;
$orange-2: #791D00;
$orange-3: #A63400;
$orange-4: #D25100;
$orange-5: #ff7400;
$orange-6: #FF821A;
$orange-7: #FF9033;
$orange-8: #FF9E4D;
$orange-9: #FFAC66;
$orange-10: #FFBA80;

$gray: #666666;
$gray-1: #4E5052;
$gray-2: #868686;
$gray-3: #B3B3B3;
$gray-4: #E5E6E7;
$gray-5: #F5F5F5;
$gray-6: #FBFBFB;

$yellow: #FFD93D;
$yellow-1: #B58500;
$yellow-2: #C89A0F;
$yellow-3: #DAAF1F;
$yellow-4: #EDC42E;
$yellow-5: #FFD93D;
$yellow-6: #FFDC46;
$yellow-7: #FFDF4E;
$yellow-8: #FFE357;
$yellow-9: #FFE65F;
$yellow-10: #FFE968;

$red: #F02222;
$red-1: #841313;
$red-2: #9A1616;
$red-3: #AF1919;
$red-4: #C51C1C;
$red-5: #DA1F1F;
$red-6: #F23838;
$red-7: #F34E4E;
$red-8: #F56464;
$red-9: #F67A7A;
$red-10: #F89191;

$blue: #1F78B4;
$blue-1: #114263;
$blue-2: #144D73;
$blue-3: #175883;
$blue-4: #196294;
$blue-5: #1C6DA4;
$blue-6: #3586BC;
$blue-7: #4C93C3;
$blue-8: #62A1CB;
$blue-9: #79AED2;
$blue-10: #8FBCDA;
$blue-11: #d9edf7;

$green: #8EB936;
$green-1: #4E661E;
$green-2: #5B7623;
$green-3: #688727;
$green-4: #74982C;
$green-5: #81A831;
$green-6: #99C04A;
$green-7: #A5C75E;
$green-8: #B0CE72;
$green-9: #BBD586;
$green-10: #C7DC9B;

$orange : #ff7400;
$orange-1 : #4D0D00;
$orange-2 : #791D00;
$orange-3 : #A63400;
$orange-4 : #D25100;
$orange-5 : #ff7400;
$orange-6 : #FF821A;
$orange-7 : #FF9033;
$orange-8 : #FF9E4D;
$orange-9 : #FFAC66;
$orange-10: #FFBA80;

$ouro-0: #FFFA8F;
$ouro-1: #FFD911;
$ouro-2: #D1B106;

$prata-0: #E2E2E2;
$prata-1: #C4C4C4;
$prata-2: #A2A2A2;

$bronze-0: #EBB463;
$bronze-1: #CF8F2F;
$bronze-2: #AB7422;

$white: #FFFFFF;
$black: #000000;

// Cores do input
$form-check-input-checked-bg-color: $purple-3;
$form-check-input-checked-border-color: $purple-3;


// DS 
$grey-50: #FBFBFB;
$grey-100: #F5F5F5;
$grey-200: #E5E6E7;
$grey-300: #B3B3B3;
$grey-400: #868686;
$grey-500: #4E5052;
$grey-600: #3A3A3A;

$purple-50: #F4EEFF;
$purple-100: #E2D1FF;
$purple-200: #C460F3;
$purple-300: #9300D9;
$purple-400: #550A90;
$purple-500: #180133;

$yellow-50: #FFFBEC;
$yellow-100: #FFE968;
$yellow-200: #FFE863;
$yellow-300: #FFD93D;
$yellow-400: #F5BD1F;
$yellow-500: #B58500;

$pink-300: #FF8FFB;
$pink-400: #E14BDB;
$pink-500: #8D0C88;

//*****************************************************
// SOCIAL
//*****************************************************
$social-facebook: #1877f2;
$social-facebookold: #3b5998;
$social-twitter: #1da1f2;
$social-youtube: #ff0000;
$social-instagrammagenta: #c32aa3;
$social-instagramblue: #4c5fd7;
$social-instagrampurple: #7232bd;
$social-instagramorange: #f46f30;
$social-instagramyellow: #ffdc7d;
$social-googleblue: #4285f4;
$social-googlered: #ea4335;
$social-googleyellow: #fbbc05;
$social-googlegreen: #34a853;
$social-pinterest: #bd081c;
$social-googleplus: #db4437;
$social-linkedin: #0a66c2;
$social-whatsappgreen: #25d366;
$social-whatsappteal1: #075e54;
$social-whatsappteal2: #128c7e;
$social-telegram: #0088cc;

//*****************************************************
// LEGACY
//*****************************************************
$color-red: #de4b39;
$color-green: #6e9714;
$color-blue: #1f78b4;
$color-yellow: #feff32;
$color-cyan: #72d3d0;
$color-purple: #834be1;
$color-orange: #f49c14;
$color-pink: #e14bd8;
$color-fz-red: #fcdedf;
$color-fz-green: #f4f7e2;
$color-fz-blue: #e4f0f7;
$color-fz-yellow: #ffffe0;
$color-fz-cyan: #e5f4f6;
$color-fz-purple: #ebe2ef;
$color-fz-orange: #feefe3;
$color-fz-pink: #fbe3ec;


// Você pode importar essas cores no seu js usando `import colors from "@Styles/variables.scss"` e ele retorna um objeto com todas as cores aí embaixo :D
:export {
  white: $white;
  black: $black;
  purple: $purple;
  purple-1: $purple-1;
  purple-2: $purple-2;
  purple-3: $purple-3;
  purple-4: $purple-4;
  purple-5: $purple-5;
  purple-6: $purple-6;
  purple-new: $purple-new;
  pink: $pink;
  pink-1: $pink-1;
  pink-2: $pink-2;
  pink-3: $pink-3;
  gray: $gray;
  gray-1: $gray-1;
  gray-2: $gray-2;
  gray-3: $gray-3;
  gray-4: $gray-4;
  gray-5: $gray-5;
  gray-6: $gray-6;
  yellow: $yellow;
  yellow-1: $yellow-1;
  yellow-2: $yellow-2;
  yellow-3: $yellow-3;
  yellow-4: $yellow-4;
  yellow-5: $yellow-5;
  yellow-6: $yellow-6;
  yellow-7: $yellow-7;
  yellow-8: $yellow-8;
  yellow-9: $yellow-9;
  yellow-10: $yellow-10;
  yellow-50: $yellow-50;
  red: $red;
  red-1: $red-1;
  red-2: $red-2;
  red-3: $red-3;
  red-4: $red-4;
  red-5: $red-5;
  red-6: $red-6;
  red-7: $red-7;
  red-8: $red-8;
  red-9: $red-9;
  red-10: $red-10;
  blue: $blue;
  blue-1: $blue-1;
  blue-2: $blue-2;
  blue-3: $blue-3;
  blue-4: $blue-4;
  blue-5: $blue-5;
  blue-6: $blue-6;
  blue-7: $blue-7;
  blue-8: $blue-8;
  blue-9: $blue-9;
  blue-10: $blue-10;
  green: $green;
  green-1: $green-1;
  green-2: $green-2;
  green-3: $green-3;
  green-4: $green-4;
  green-5: $green-5;
  green-6: $green-6;
  green-7: $green-7;
  green-8: $green-8;
  green-9: $green-9;
  green-10: $green-10;
  ouro-0: $ouro-0;
  ouro-1: $ouro-1;
  ouro-2: $ouro-2;
  prata-0: $prata-0;
  prata-1: $prata-1;
  prata-2: $prata-2;
  bronze-0: $bronze-0;
  bronze-1: $bronze-1;
  bronze-2: $bronze-2;
  orange: $orange;
  orange-1: $orange-1;
  orange-2: $orange-2;
  orange-3: $orange-3;
  orange-4: $orange-4;
  orange-5: $orange-5;
  orange-6: $orange-6;
  orange-7: $orange-7;
  orange-8: $orange-8;
  orange-9: $orange-9;
  orange-10: $orange-1;

  social-facebook: $social-facebook;
  social-facebookold: $social-facebookold;
  social-twitter: $social-twitter;
  social-youtube: $social-youtube;
  social-instagrammagenta: $social-instagrammagenta;
  social-instagramblue: $social-instagramblue;
  social-instagrampurple: $social-instagrampurple;
  social-instagramorange: $social-instagramorange;
  social-instagramyellow: $social-instagramyellow;
  social-googleblue: $social-googleblue;
  social-googlered: $social-googlered;
  social-googleyellow: $social-googleyellow;
  social-googlegreen: $social-googlegreen;
  social-pinterest: $social-pinterest;
  social-googleplus: $social-googleplus;
  social-linkedin: $social-linkedin;
  social-whatsappgreen: $social-whatsappgreen;
  social-whatsappteal1: $social-whatsappteal1;
  social-whatsappteal2: $social-whatsappteal2;
  social-telegram: $social-telegram;
}
