@import "@Styles/variables";

.container {
    display: flex;
    width: 100%;
    max-width: 420px;
    min-height: calc(100vh - 72px);
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    position: relative;
}

.block404 {
    font-weight: bold;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 2em;
    left: 0;
    right: 0;
    width: 120px;
    height: 32px;
    margin: auto;
    font-size: 3em;
    font-weight: bold;
    color: #fff;
}
