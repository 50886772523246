// REACT-SELECT *********************************************************
.input-group {
  .react-select {
    flex: 1 1 auto;
    width: 1%;
    &:has(+ .input-group-text) {
      .react-select__control {
        border-right: none !important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .input-group-text {
    &+.react-select {
      .react-select__control {
        border-left: none !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}