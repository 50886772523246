@import "variables";
@import "custom/defaults";

$color-list: (
        "purple": $purple,
        "purple-1": $purple-1,
        "purple-2": $purple-2,
        "purple-3": $purple-3,
        "purple-4": $purple-4,
        "purple-5": $purple-5,
        "purple-6": $purple-6,
        "purple-new": $purple-new,
        "pink": $pink,
        "pink-1": $pink-1,
        "pink-2": $pink-2,
        "pink-3": $pink-3,
        "gray": $gray,
        "gray-1": $gray-1,
        "gray-2": $gray-2,
        "gray-3": $gray-3,
        "gray-4": $gray-4,
        "gray-5": $gray-5,
        "gray-6": $gray-6,
        "yellow": $yellow,
        "yellow-1": $yellow-1,
        "yellow-2": $yellow-2,
        "yellow-3": $yellow-3,
        "yellow-4": $yellow-4,
        "yellow-5": $yellow-5,
        "yellow-6": $yellow-6,
        "yellow-7": $yellow-7,
        "yellow-8": $yellow-8,
        "yellow-9": $yellow-9,
        "yellow-10": $yellow-10,
        "yellow-50": $yellow-50,
        "red": $red,
        "red-1": $red-1,
        "red-2": $red-2,
        "red-3": $red-3,
        "red-4": $red-4,
        "red-5": $red-5,
        "red-6": $red-6,
        "red-7": $red-7,
        "red-8": $red-8,
        "red-9": $red-9,
        "red-10": $red-10,
        "blue": $blue,
        "blue-1": $blue-1,
        "blue-2": $blue-2,
        "blue-3": $blue-3,
        "blue-4": $blue-4,
        "blue-5": $blue-5,
        "blue-6": $blue-6,
        "blue-7": $blue-7,
        "blue-8": $blue-8,
        "blue-9": $blue-9,
        "blue-10": $blue-10,
        "green": $green,
        "green-1": $green-1,
        "green-2": $green-2,
        "green-3": $green-3,
        "green-4": $green-4,
        "green-5": $green-5,
        "green-6": $green-6,
        "green-7": $green-7,
        "green-8": $green-8,
        "green-9": $green-9,
        "green-10": $green-10,
        "ouro-0": $ouro-0,
        "ouro-1": $ouro-1,
        "ouro-2": $ouro-2,
        "prata-0": $prata-0,
        "prata-1": $prata-1,
        "prata-2": $prata-2,
        "bronze-0": $bronze-0,
        "bronze-1": $bronze-1,
        "bronze-2": $bronze-2,
        "orange" : $orange,
        "orange-1": $orange-1,
        "orange-2": $orange-2,
        "orange-3": $orange-3,
        "orange-4": $orange-4,
        "orange-5": $orange-5,
        "orange-6": $orange-6,
        "orange-7": $orange-7,
        "orange-8": $orange-8,
        "orange-9": $orange-9,
        "orange-10": $orange-10,
        "white": $white,
        "black": $black,
        "social-facebook": $social-facebook,
        "social-facebookold": $social-facebookold,
        "social-twitter": $social-twitter,
        "social-youtube": $social-youtube,
        "social-instagrammagenta": $social-instagrammagenta,
        "social-instagramblue": $social-instagramblue,
        "social-instagrampurple": $social-instagrampurple,
        "social-instagramorange": $social-instagramorange,
        "social-instagramyellow": $social-instagramyellow,
        "social-googleblue": $social-googleblue,
        "social-googlered": $social-googlered,
        "social-googleyellow": $social-googleyellow,
        "social-googlegreen": $social-googlegreen,
        "social-pinterest": $social-pinterest,
        "social-googleplus": $social-googleplus,
        "social-linkedin": $social-linkedin,
        "social-whatsappgreen": $social-whatsappgreen,
        "social-whatsappteal1": $social-whatsappteal1,
        "social-whatsappteal2": $social-whatsappteal2,
        "social-telegram": $social-telegram,
);

@each $name, $color in $color-list {

  a.text-#{$name} {
    color: #{$color} !important;

    &:hover {
      color: darken($color, 15%) !important;
    }

    &:active {
      color: lighten($color, 7.5%) !important;
    }
  }
  .text-#{$name} {
    color: #{$color} !important;
  }

  .bg-#{$name}, .background-color-#{$name} {
    background-color: #{$color} !important;
  }

  .btn-border-#{$name}, .btn-bordered-#{$name} {
    color: #{$color} !important;

    &:hover, &:focus {
      color: #{$color} !important;
    }
  }
  .btn-background-#{$name}, .btn-bg-#{$name} {
    background-color: #{$color} !important;
  }

  .border-#{$name} {
    border: 1px solid;
    border-color: $color !important;
  }

  .border-dashed-#{$name} {
    border: 3px dashed;
    border-color: $color !important;
  }
  
  .border-color-#{$name} {
    border-color: $color !important;
  }
}



// #########################################################
// width
// #########################################################
@for $i from 1 through 100 {
  .w-#{$i} {width: #{$i}#{"%"} !important;}
  .vw-#{$i} {width: #{$i}#{"vw"} !important;}
}

// #########################################################
// height
// #########################################################
@for $i from 1 through 100 {
  .h-#{$i} {height: #{$i}#{"%"} !important;}
  .vh-#{$i} {height: #{$i}#{"vh"} !important;}
}

// #########################################################
// Cursor
// #########################################################
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-help {
  cursor: help;
}

.cursor-disabled, .cursor-not-allowed {
  cursor: not-allowed !important
}

.cursor-grabber {
  cursor: grab !important;
  &:active, &.grabbing {
    cursor: grabbing !important;
  }
}
