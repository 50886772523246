
.card-item{
  display: block;
  font-family: $font-rounded;
  margin-bottom: 1em;
  font-size: 13px;
  width: 100%;
  .header, .body, .footer {
    display: flex;
    width: 100%;
    padding: 8px;
  }
  .header {
    align-items: center;
    border-radius: 12px 12px 0 0;
    background-color: $gray-5;
    padding-bottom: 18px;
    border: 1px solid $gray-4;
    .icon {
      height: 38px;
      width: 38px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $purple-3;
      color: #fff;
      font-size: 20px;
      margin-right: 8px;
      flex: 0 0 38px;
    }
    .block {
      // word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      flex: 1;
      h2 {
        display: block;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 20px;
        line-height: 20px;
        font-size: 15px;
        font-weight: 500;
        color: $purple-3;
        width: 100%;
        margin: auto;
      }
      span {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
  
  .body {
    background-color: #fff;
    border: 1px solid $gray-4;
    border-bottom: none;
    margin-top: -12px;
    border-radius: 12px 12px 0 0;
    &:last-child {
      border-radius: 12px;
      border-bottom: 1px solid $gray-4;
    }
    
    .block {
      border-radius: 8px;
      border: 2px solid $purple-3;
      margin-right: 1em;
      padding: 0.5em 1em;
      flex: 0 1 200px;
      &:last-child {
        margin-right: 0;
      }

      .title {
        color: $purple-2;
        text-align: center;
        margin-bottom: 0.5em;
        line-height: 1.1;
        height: 30px;
        display: block;
        font-weight: 500;
      }
      .info {
        display: block;
        font-size: 11px;
        text-align: center;
      }
    }
  }

  .footer {
    align-items: flex-end;
    border: 1px solid $gray-4;
    border-radius: 0 0 12px 12px;
  }

  &.inactive  {
    .header {
      background-color: #fff;
      .name, .icon {
        filter: grayscale(1);
        opacity: 0.55;
      }
    }
    .body {
      filter: grayscale(1);
      opacity: 0.55;
    }
  }
}