dl.topics {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  border-bottom: 1px dashed $gray-4;
  min-height: 28px;
  &:last-of-type {
    border-bottom: none;
  }
  dt {
    flex: 0 1 100%;
    font-weight: 500;
  }

  dt, dd {
    margin: 0;
  }
}