@import '@Styles/variables';

.demanda {
    height: 100vh;

    .transition {
        width: 2000px;
        height: 2000px;
        position: fixed;
        z-index: 999;
        border-radius: 100%;
        background-color: white;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;
        opacity: 1;
    }

    .transition.go {
        opacity: 0;
        transform: scale(0);
    }

    .row {
        height: 100%;
        // width: 97%;
    }

    .topbar-margin {
        margin-top: 160px;

        .wizard {
        z-index: 10;
        }
    }

    .barrier-back {
        background-color: rgba(255, 255, 255, 0.7);
        width: 400px;
        z-index: 5;
        height: 100%;
        top: 0vh;
        position: absolute;

        .magic-circle {
        width: 100%;
        height: 30px;
        background-color: transparent;
        border: 6px solid #d234eb;
        position: absolute;
        bottom: 0px;
        animation: shake2 0.3s infinite;
        border-radius: 100%;
        -moz-box-shadow: inset 0 0 9px #a51a77;
        -webkit-box-shadow: inset 0 0 9px #a51a77;
        box-shadow: inset 0 0 9px #a51a77;
        }
    }

    .barrier-front {
        z-index: 15;
        background-color: rgba(255, 255, 255, 0.8);
        width: 400px;
        height: 100%;
        top: 0vh;
        position: absolute;

        .magic-circle {
        width: 100%;
        height: 30px;
        background-color: transparent;
        border-bottom: 4px solid #d234eb;
        position: absolute;
        animation: shake2 0.3s infinite;
        bottom: 0px;
        border-radius: 100%;
        box-shadow: 1px 5px 5px #d61897;
        }
    }

    textarea {
        padding-left:10px;
        padding-top:5px;
    }

    .swal-input-1, .swal-input-2, .swal-input-3, .swal-input-4, .swal-input-5 {

        &.error {
        border: 1px solid red !important;
        }
    }

    .fdw-images-gif {
        opacity:.4;
    }

    .an1 {
        animation: an1 2s forwards;
    }
    @keyframes an1 {
        0% {
        top: 0%;
        }
        100% {
        top: -5%;
        }
    }

    .an2 {
        animation: an2 2s forwards;
    }
    @keyframes an2 {
        0% {
        top: -5%;
        }
        100% {
        top: -10%;
        }
    }

    .an3 {
        animation: an3 2s forwards;
    }
    @keyframes an3 {
        0% {
        top: -10%;
        }
        100% {
        top: -15%;
        }
    }

    .an4 {
        animation: an4 2s forwards;
    }
    @keyframes an4 {
        0% {
        top: -15%;
        }
        100% {
        top: -20%;
        }
    }

    .an5 {
        animation: an5 2s forwards;
    }
    @keyframes an5 {
        0% {
        top: -20%;
        }
        100% {
        top: -25%;
        }
    }

    .an6 {
        animation: an6 2s forwards;
    }
    @keyframes an6 {
        0% {
        top: -25%;
        }
        100% {
        top: -35%;
        }
    }

    .an7 {
        animation: an7 2s forwards;
    }
    @keyframes an7 {
        0% {
        top: -35%;
        }
        100% {
        top: -40%;
        }
    }

    .an8 {
        animation: an8 2s forwards;
    }
    @keyframes an8 {
        0% {
        top: -40%;
        }
        100% {
        top: -45%;
        }
    }

    .an9 {
        animation: an9 2s forwards;
    }
    @keyframes an9 {
        0% {
        top: -45%;
        }
        100% {
        top: -55%;
        }
    }

    .an10 {
        animation: an10 2s forwards;
    }
    @keyframes an10 {
        0% {
        top: -55%;
        }
        100% {
        top: -60%;
        }
    }

    .an11 {
        animation: an11 2s forwards;
    }
    @keyframes an11 {
        0% {
        top: -60%;
        }
        100% {
        top: -70%;
        }
    }

    .an12 {
        animation: an12 2s forwards;
    }
    @keyframes an12 {
        0% {
        top: -70%;
        }
        100% {
        top: -80%;
        }
    }

    .an13 {
        animation: an13 2s forwards;
    }
    @keyframes an13 {
        0% {
        top: -80%;
        }
        100% {
        top: -90%;
        }
    }

    .an14 {
        animation: an14 2s forwards;
        .magic-circle {
        animation: close 0.2s forwards 2s, shake2 0.3s infinite;
        }
    }
    @keyframes an14 {
        0% {
        top: -90%;
        }
        100% {
        top: -100%;
        }
    }
}

.demanda-container {
    max-width: 30vw;
    min-width: 30vw;
    
    .space-fix {
    margin-top: 100px;
    }
}

@keyframes shake2 {
    0% {
    transform: rotateY(0deg);
    }
    50% {
    transform: rotateY(15deg);
    }
    100% {
    transform: rotateY(0deg);
    }
}

@keyframes close {
    0% {
    max-height: 100%;
    max-width: 100%;
    }

    99% {
    max-height: 100%;
    max-width: 100%;
    }

    100% {
    max-height: 0%;
    max-width: 0%;
    border-width: 0;
    }
}

.gif-container {
    img {
        width: 400px;
        opacity: 0.7;
        transition: ease-in;
    }
}

.simulacao {
    position: relative;
    // min-height: 100%;
    width: 100%;

    .simulador {
        width: 100%;
        min-height: 82vh;
    }

    input[type="number"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
    }
    
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }
}

.modal-perfil {
    .modal-title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #492b7c;

    .destak {
        font-size: 28px;
        text-decoration: underline;
        font-weight: 900;
    }

    &.invalid {
        color: red;
    }

    &.valid {
        color: green;
    }

    &.huge {
        font-size: 29px;
    }
    }

    .img-money {
        width: 100%;
        height: 150px;
        margin-top: -20px;
        margin-bottom: -30px;
        background: url('~@Assets/money-pile.svg') no-repeat;
        background-position: center;
    }

    .tiny {
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        text-align: center;
        letter-spacing: -0.4px;
        line-height: 1px !important;
    }

    .ate {
        color: rgb(255, 72, 0);;
        line-height: 16px;
    }

    .money {
        font-style: normal;
        font-weight: bold;
        font-size: 45px;
        line-height: 45px;
        text-align: center;
        color: rgb(255, 72, 0);
        text-decoration: underline;
    }

    .sub {
        font-weight: 600;
        font-size: 11px;
        margin-top: 5px;
        line-height: 12px;
        text-align: center;
        color: #b8b6b6;
        height: 10px;
        margin-top: 10px;
        margin-bottom: 7px;
    }

    .main {
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 25px;
        text-align: center;
        letter-spacing: -0.4px;
        color: #492b7c;
    }

    .main-2 {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 0px;
        text-align: start !important;
        letter-spacing: -0.4px;
        color: #492b7c;
    }

    .separator {
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted rgb(165, 165, 165);
        margin-bottom: 10px;
    }
}

.graphs {
    margin-top: 0px;
    min-height: 180px;

    .graph-percentage {
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        color: #b487fe;
        margin-bottom: 5px;
    }

    .graph {
        background: #b487fe;
        width: 100%;
        height: 90px;

        &.two {
            height: 40px;
            background: #e2d1ff;
        }
    }

    .graph-content {
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        margin-top: 6px;
        letter-spacing: -0.3px;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #4e5052;
    }
}

.graph {
    position: relative;
    display: inline-block;
    @for $i from 0 to 100 {
        &.x#{$i} {
            @if $i < 9 {
                height: #{$i + 1}px !important;
            } @else {
                height: #{$i}px !important;
            }
        }
    }
}

.graph .tooltip {
    opacity: 0;
    margin-left: -30px;
    margin-top: 20px;
    padding: 12px;
    width: 250px;
    background-color: black;
    font-size: 12px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    pointer-events: none;
}

/* Show the tooltip text when you mouse over the tooltip container */
.graph:hover .tooltip {
    opacity: 1;
}

.gastos {
    .input-range {
        transform: rotate(90deg);
        margin-left: -140px;
        margin-top: 60px;
    }
    
    .input-range__track--background {
        height: 12px;
        background: #492b7c;
        width: 170px;
    }
    
    .input-range__track--active {
        background: #834be1;
        border-color: #834be1;
        height: 12px;
        width: 170px;
    }

    .input-range__slider {
        width: 12px;
        border-radius: 10px;
        height: 30px;
        margin-top: -21px;
        background-color: #868686;
        border-color: #868686;
    }
    
    .input-range__label-container {
        color: white;
    }
}

@media (max-width: 1439px) {
    #wizard-demanda,
    #gif-demanda,
    #stepper {
        display: none !important;
    }

    .demanda {
        .row {
            width: 100%;
        }
    }
}

@media (max-width: 576px) {
    .demanda {
        height: 98vh;
    }
    .demanda-container {  
        max-width: 300px;
        min-width: 300px;
    }
}

@media (max-width: 991px) {
    .demanda-container {
        .number-input {
            display: flex;
            align-self: center;
            justify-content: center;
        }

        .quantity {
            width: 80% !important;
        }

        .space-fix {
            margin-top: 0;
        }
    }
    
    #wizard-container {
        display: none !important;
    }

    .graph {
        .tooltip {
            display: none;
        }
    }

    .gastos {
        .input-range {
            transform: rotate(0);
        }
    }
}