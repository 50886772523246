// **********************************************************************
// Base botões
// **********************************************************************
.button {
  border-radius: var(--button-border-radius);
  min-height: var(--button-min-height);
  min-width: var(--button-min-width);
  padding: var(--button-padding);
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  font-family: var(--button-font-family);
  gap: var(--button-gap);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  border: none;
  user-select: none;
  vertical-align: middle;
  margin-bottom: 0;
  position: relative;
  white-space: nowrap;
  background-color: transparent;
  outline: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    outline: 2px solid var(--color-purple-50);
  }

  &.xs,
  &.btn-xs {
    min-height: var(--button-min-height-xs);
    min-width: var(--button-min-width-xs);
    padding: var(--button-padding-xs);
    font-size: var(--button-font-size-xs);
  }

  &.sm,
  &.btn-sm {
    min-height: var(--button-min-height-sm);
    min-width: var(--button-min-width-sm);
    padding: var(--button-padding-sm);
    font-size: var(--button-font-size-sm);
  }

  &.md,
  &.btn-md {
    min-height: var(--button-min-height-md);
    min-width: var(--button-min-width-md);
    padding: var(--button-padding-md);
    font-size: var(--button-font-size-md);
  }

  &.lg,
  &.btn-lg {
    min-height: var(--button-min-height-lg);
    min-width: var(--button-min-width-lg);
    padding: var(--button-padding-lg);
    font-size: var(--button-font-size-lg);
  }

  &.primary,
  &.btn-primary {
    background-color: var(--color-primary);
    color: #fff;

    &:hover {
      background-color: var(--color-primary-500);
    }

    &:active,
    &.active,
    &.btn-active,
    &.show,
    &.btn-show {
      background-color: var(--color-primary-700);
    }

    &:focus {
      outline: none;
    }
  }

  &.secondary,
  &.btn-secondary {
    background-color: var(--color-secondary-40);
    color: #000;

    &:hover {
      background-color: var(--color-secondary-100);
    }

    &:active,
    &.active,
    &.btn-active,
    &.show,
    &.btn-show {
      background-color: var(--color-black);
      color: #fff;
    }

    &:focus {
      outline: none;
    }
  }


  &.danger,
  &.btn-danger {
    background-color: var(--color-danger-default);
    color: #fff;

    &:hover {
      background-color: var(--color-danger-300);
    }

    &:active,
    &.active,
    &.btn-active,
    &.show,
    &.btn-show {
      background-color: var(--color-danger-400);
      outline: 2px solid var(--color-danger-100);
    }

    &:focus {
      outline: 2px solid var(--color-danger-100);
    }
  }


  &.success,
  &.btn-success {
    background-color: var(--color-success-default);
    color: #fff;

    &:hover {
      background-color: var(--color-success-300);
    }

    &:active,
    &.active,
    &.btn-active,
    &.show,
    &.btn-show {
      background-color: var(--color-success-400);
      outline: 2px solid var(--color-success-100);
    }

    &:focus {
      outline: 2px solid var(--color-success-100);
    }
  }


  &.warning,
  &.btn-warning {
    background-color: var(--color-warning-200);
    color: var(--color-warning-400);

    &:hover {
      background-color: var(--color-warning-300);
    }

    &:active,
    &.active,
    &.btn-active,
    &.show,
    &.btn-show {
      background-color: var(--color-warning-400);
      color: #fff;
      outline: 2px solid var(--color-warning-100);
    }

    &:focus {
      outline: 2px solid var(--color-warning-100);
    }
  }


  &.info,
  &.btn-info {
    background-color: var(--color-info-default);
    color: #fff;

    &:hover {
      background-color: var(--color-info-300);
    }

    &:active,
    &.active,
    &.btn-active,
    &.show,
    &.btn-show {
      background-color: var(--color-info-400);
      outline: 2px solid var(--color-info-100);
    }

    &:focus {
      outline: 2px solid var(--color-info-100);
    }
  }

  &.question,
  &.btn-question {
    background-color: var(--color-question-default);
    color: #fff;

    &:hover {
      background-color: var(--color-question-300);
    }

    &:active,
    &.active,
    &.btn-active,
    &.show,
    &.btn-show {
      background-color: var(--color-question-400);
      outline: 2px solid var(--color-question-100);
    }

    &:focus {
      outline: 2px solid var(--color-question-100);
    }
  }


  &.link,
  &.btn-link {
    background-color: transparent;
    color: var(--color-link-blue-default);
    text-decoration: none;
    
    &:hover {
      background-color: transparent;
      color: var(--color-link-blue-100);
    }
    
    &:active,
    &.active,
    &.btn-active,
    &.show,
    &.btn-show {
      background-color: transparent;
      color: var(--color-link-blue-100);
    }

    &:focus {
      outline: 0;
    }
  }

  &.outline,
  &.btn-outline {
    background-color: transparent;
    color: var(--color-purple-default);
    box-shadow: inset 0 0 0 2px var(--color-purple-500);

    &:hover {
      color: var(--color-purple-600);
      box-shadow: inset 0 0 0 3px var(--color-purple-600);
    }
    
    &:active,
    &.active,
    &.btn-active,
    &.show,
    &.btn-show {
      color: var(--color-purple-700);
      box-shadow: inset 0 0 0 3px var(--color-purple-700);
    }

    &:focus {
      outline: none;
    }
  }

  &.ghost, 
  &.btn-ghost {
    background-color: transparent;
    color: var(--color-purple-default);

    &:hover {
      background-color: var(--color-purple-50);
    }
    
    &:active,
    &.active,
    &.btn-active,
    &.show,
    &.btn-show {
      color: var(--color-purple-700);
      outline: 2px solid var(--color-purple-100);
    }

    &:focus {
      outline: 2px solid var(--color-purple-100);
    }
  
  }


  &[disabled],
  &.disabled,
  &.btn-disabled {
    pointer-events: none !important;
    background-color: var(--color-gray-100) !important;
    color: white !important;
  }
}