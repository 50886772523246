@import '@Styles/variables';

.routerError {
  // display: flex;
  width:100%;
  height: 100%;
  overflow: hidden;
  // align-items: center;
  // justify-content: center;
  padding: 2em;
}
.error {
  width: 100%;
  background-color: rgba($yellow-1,0.1);
  padding: 0.25em;
  border-radius: 4px;
  border: 1px solid rgba($yellow-1,0.1);
}