.nav {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  width: 100%;


  .nav-link {
    width: 100%;
    text-align: center;
    font-family: $font-rounded;
    font-weight: bold;
    padding: 15px;
    color: $gray-3;
    font-size: 18px;
    border: none;
    border-bottom: 2px solid $gray-4;
  }

  .nav-link.active {
    border: none;
    color: $purple-3;
    border-bottom: 2px solid $purple-3;
  }
}

.tab-content {
  padding: var(--spacing-2) 0;
}

// Generate classes for each number of columns
@for $i from 1 through 12 {
  .nav.nav-#{$i} {
    grid-template-columns: repeat(auto-fit, minmax(min(100% / #{$i}, 150px), 1fr));
  }
}

// Generate classes for each breakpoint and number of columns
@each $breakpoint, $size in $grid-breakpoints {
  @for $i from 1 through 12 {
    @media (min-width: #{$size}) {
      .nav.nav-#{$breakpoint}-#{$i} {
        grid-template-columns: repeat(auto-fit, minmax(min(100% / #{$i}, 150px), 1fr));
      }
    }
  }
}