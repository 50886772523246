@property --progress-value {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

@keyframes progress {
  to {
    --progress-value: 100;
  }
}

@keyframes sweet-alert-animate-success-line-tip {
  0% {
    margin-top: 8px;
    opacity: 0;
  }

  50% {
    margin-top: 8px;
    opacity: 0;
  }

  80% {
    margin-top: -4px;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes sweet-alert-animate-success-line-long {
  0% {
    margin-top: 6px;
    opacity: 0;
  }

  50% {
    margin-top: 6px;
    opacity: 0;
  }

  80% {
    margin-top: -4px;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes sweet-alert-animate-fill-circle {
  0% {
    background: conic-gradient(transparent 100%, white 0);
  }

  100% {
    background: conic-gradient(transparent 0%, white 0);
  }
}


.swal2-container {
  z-index: var(--z-index-sweet-alert);
  &.swal2-backdrop-show.sweet-alert-backdrop-show {
    background-color: transparentize(map_get($backdrop, 'color'), map_get($backdrop, 'opacity'));
  }

  &.sweet-alert {
    .sweet-alert-popup {
      border-radius: var(--border-radius-sm);
      padding: var(--spacing-5) var(--spacing-4);
      overflow: hidden;
      box-shadow: var(--box-shadow-100);

      .sweet-alert-icon {
        margin-top: 0;
        width: 40px;
        height: 40px;
        border-width: 4px;

        &::before {
          content: '';
          position: absolute;
          top: -5px;
          left: -5px;
          width: 50px;
          height: 50px;
          box-sizing: border-box;
          background-color: #fff;
          border-radius: 50%;
          background: conic-gradient(transparent calc(var(--progress-value) * 1%), white 0);
          animation: progress 0.5s 0.3s 1 cubic-bezier(1, 0.5, 0.75, 1) forwards;
          // animation: sweet-alert-animate-fill-circle 2s;
        }


        &.swal2-error {
          .swal2-x-mark {
            z-index: 5 !important;

            [class^=swal2-x-mark-line] {
              top: 19px;
              width: 20px;
              height: 4px;
              border-radius: 4px;
            }

            .swal2-x-mark-line-left {
              left: 10px;
            }

            .swal2-x-mark-line-right {
              right: 10px;
            }
          }
        }

        &.swal2-success {
          animation: swal2-animate-error-icon .5s;

          [class^=swal2-success-circular-line],
          .swal2-success-ring,
          .swal2-success-fix {
            width: 40px;
            height: 40px;
            display: none !important;
            z-index: 5 !important;
          }

          .swal2-success-line-tip {
            top: 21px;
            left: 6px;
            width: 12px;
            height: 4px;
            animation: sweet-alert-animate-success-line-tip 0.5s;
          }

          .swal2-success-line-long {
            top: 19px;
            right: 5px;
            width: 22px;
            height: 4px;
            animation: sweet-alert-animate-success-line-long 0.5s;
          }
        }

        &.swal2-warning,
        &.swal2-info,
        &.swal2-question {
          .swal2-icon-content {
            z-index: 5 !important;
            font-size: 34px;
            font-weight: 700;
            animation: sweet-alert-animate-letter .5s;
            margin-top: 3px;
          }
        }
      }

      .sweet-alert-close-button {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background: none;
        background-color: var(--color-gray-50);
        padding: 0;
        position: absolute;
        right: var(--spacing-2);
        top: var(--spacing-2);
        opacity: 0.5;
        color: var(--color-gray-90);
        font-size: 26px;
        font-weight: 700;
        font-family: var(--font-family-base);
        transition: color 0.1s, box-shadow 0.1s;

        &:hover {
          color: var(--color-black);
        }
      }

      .sweet-alert-title {
        color: #2B2B2B;
        font-weight: 700;
        font-size: 20px;
        padding: 0;
        margin: 0 0 var(--spacing-4);
      }

      .sweet-alert-html-container {
        color: var(--color-gray-900);
        padding: 0;
        margin: 0 0 var(--spacing-4);
      }

      .sweet-alert-actions {
        gap: var(--spacing-2);

        .swal2-deny {
          order: 0;
          margin-right: var(--spacing-3);
        }
        .swal2-cancel {
          order: 1;
        }
        .swal2-confirm {
          order: 2;
        }
      }
    }
  }
}