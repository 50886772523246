$spacing: var(--spacing-4) var(--spacing-4);

.accordion {
  border: var(--accordion-border);
  border-radius: var(--accordion-border-radius);
  background-color: var(--accordion-background-color);

  &:not(.variant) {
    .accordion-button {
      background-color: var(--color-gray-50);
      color: var(--color-secondary);
      border: none;
      border-radius: 0;
      min-height: var(--accordion-item-min-height);
      padding: var(--spacing-2);
      font-size: 13px;
      font-weight: bold;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      outline: none !important;
      box-shadow: none !important;

      &::after {
        background-image: none;
        content: "\f0d7";
        font-family: var(--fa-style-family, "Font Awesome 6 Pro");
        font-weight: var(--fa-style, 900);
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 18px;
        position: absolute;
        right: 0;
        width: 34px;
        transition: var(--bs-accordion-btn-icon-transition);
      }
    }
  }



  &.variant {

    .accordion-button {
      background-color: var(--color-white);
      color: var(--color-black);
      border: none;
      border-radius: 0;
      min-height: var(--accordion-item-min-height);
      padding: $spacing;
      font-size: var(--font-size-md);
      font-weight: 600;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      outline: none !important;
      box-shadow: none !important;

      &::after {
        background-image: none;
        content: "\f107";
        font-family: var(--fa-style-family, "Font Awesome 6 Pro");
        font-weight: var(--fa-style, 900);
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 18px;
        position: absolute;
        right: var(--spacing-4);
        // width: 34px;
        transition: var(--bs-accordion-btn-icon-transition);
      }

      &::before {
        content: '';
        width: calc(100% - 2 * var(--spacing-4));
        // height: 1px;
        border-bottom: var(--accordion-border);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;

      }
    }

    .accordion-item {
      &:last-child {
        .accordion-button.collapsed {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  .accordion-body {
    padding: $spacing;
  }


  .accordion-item {
    border: none;
  }
}