// **********************************************************************
// Dropdown + <Dropdown.Menu/> Reset
// **********************************************************************
.dropdown {
  .dropdown-menu {
    background-color: #fff;
    border-color: var(--input-color-border);
    border-radius: var(--border-radius-sm);
    z-index: var(--z-index-dropdown);

    .dropdown-header {
      background-color: var(--color-gray-50);
      color: var(--color-secondary);
      font-size: 13px;
      min-height: var(--input-height);
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: var(--spacing-2);
      font-weight: bold;
    }

    .dropdown-divider {
      margin: 0;
      border-top: 1px solid var(--input-color-border);
    }

    .dropdown-item {
      border-radius: 0;
      padding: var(--spacing-2);
      min-height: var(--dropdown-item-min-height);
      display: flex;
      align-items: center;
      background-color: transparent;
      cursor: pointer;
      color: var(--color-secondary);
      font-weight: 600;

      &:hover {
        background-color: var(--color-purple-50);
      }

      .icon {
        width: 22px;
        flex: 0 0 22px;
        text-align: center;
        margin-right: 6px;
        margin-left: -6px;
      }

      &:active,
      &.active {
        // background-color: #e9ecef !important;
        background-color: var(--color-purple-500) !important;
        // box-shadow: inset 0px 4px 4px rgba(0,0,0,0.25), inset 0 0 0 64px rgba(0,0,0,0.05);
        color: #fff !important;
        outline: none;
      }

      &.selected {
        background-color: var(--color-purple-default) !important;
        color: #fff !important;

      }

      button {
        background: none;
        border: none;
        padding: 0;
        margin: 0;
      }
    }

  }

  .dropdown-toggle {
    height: 38px;
    min-width: 38px;
    border: none;
    text-align: center;
    background-color: transparent;
    border-radius: 8px;
    color: #000;
    padding: 0;
    min-height: auto;

    &.dropdown-toggle-ellipsis {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    cursor: pointer;

    // i[class*="fa"] {
    //   font-size: 20px;
    // }
    &:not(.show-arrow)::after {
      display: none;
    }

    &.rounded {
      border-radius: 100%;
    }

    &:hover {
      background-color: var(--color-secondary-100);
      box-shadow: none;
    }
  }

  &.show {
    .dropdown-toggle-ellipsis {
      background-color: var(--color-black);
      color: #fff;
    }
  }

}