@import "@Styles/variables";


.logo {
  width: 109px;
  height: 109px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: auto;
  border-radius: 100%;
  z-index: 0;

  svg {
    position: absolute;
    width: 42px;

    path {
      fill: $purple-4;
    }
  }
}

.contents {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  padding: 3em;
  display: flex;
  // min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: $purple-4;

  .text {
    position: relative;
    z-index: 2;
    margin-top: 0.5em;
  }
}