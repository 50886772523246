@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=JetBrains+Mono:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "init";
@import "imports";
@import "custom";
@import "responsive";

// **********************************************************************
// Só adicione classes e coisas aqui se forem de coisas utilizadas em
// mais de um lugar e não for um componente, basicamente customização
// de css de alguma biblioteca de terceiros; dê preferencia para css
// modules para não haver conflito de layout de alguma outra tela
// -----------------------------------------
// Verifique em custom também se já não existe algo padronizado para
// o que necessita criar
// **********************************************************************

// **********************************************************************
// Padrões Body
// **********************************************************************
html {
  overflow: hidden;
  overflow-y: unset;
}

html,
body {
  color: $gray-1;
  padding: 0;
  margin: 0;
  font-family: "Nunito", sans-serif;
  letter-spacing: 0px;
  font-size: 13px;

  scroll-behavior: smooth;

  /* Smoothing */
  font-smooth: always;
  font-smooth: 2em;
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    box-sizing: border-box;
    /* Smoothing */
    font-smooth: always;
    font-smooth: 2em;
    text-rendering: optimizeLegibility;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}


#root {
  display: block;
  width: 100%;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: $purple-3;

  &:hover {
    color: $purple-2;
  }
}

select {
  appearance: auto !important;
}

hr {
  border-top-color: $gray-2;
}

.container {
  max-width: 920px;
}

// **********************************************************************
// Botão Close, ripoff do swal
// **********************************************************************
.close {
  z-index: 2;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  overflow: hidden;
  transition: color 0.1s, box-shadow 0.1s;
  border: none;
  border-radius: 5px;
  background: 0 0;
  color: #ccc;
  font-family: serif;
  font-family: monospace;
  font-size: 18px;
  cursor: pointer;
  justify-self: end;
  display: flex;
  user-select: none;

  &:hover {
    transform: none;
    background: 0 0;
    color: #f27474;
  }

  &::before {
    font-family: var(--fa-style-family, "Font Awesome 6 Pro");
    font-weight: var(--fa-style, 900);
    content: "\f00d";
  }
}

@media screen and (max-width: 991px) {
  .close {
    width: 48px;
    height: 48px;
  }
}

// *********************************************************************// Padrões nprogress
// **********************************************************************
#nprogress {
  .bar {
    background: $purple-3;
    height: 6px;
  }

  .peg {
    box-shadow: 0 0 10px $purple-3, 0 0 5px $purple-3;
  }

  .spinner-icon {
    border-top-color: $purple-3;
    border-left-color: $purple-3;
    border-width: 4px;
    width: 32px;
    height: 32px;
  }

  .spinner {
    top: auto;
    bottom: 15px;
    right: 15px;
  }
}

// **********************************************************************
// Padrões form control
// **********************************************************************
.form-group {
  label {
    font-size: 13px;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
  }
}

.form-control {
  min-height: $form-control-height;
  border: 1px solid $gray-3;

  &[type="password"] {
    letter-spacing: 0.2em;
  }

  &.is-invalid {
    border-color: $red-5;
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  &.is-valid {
    border-color: $green-5;
  }
}

fieldset[disabled] {
  pointer-events: none;
  user-select: none;
  pointer-events: none;
  opacity: 0.55;
}

// **********************************************************************
// Padrões de Toastify
// **********************************************************************
.Toastify {
  .Toastify__toast {
    font-family: $font-rounded;
  }

  .Toastify__progress-bar-theme--light {
    // background: linear-gradient(to right, $pink-2 0%, $purple-4 100%);
  }
}

// **********************************************************************
// Padrões de Sweet Alert
// **********************************************************************
.swal2-container {
  backdrop-filter: blur(4px);
  z-index: 3000 !important;

  .swal2-popup {
    box-shadow: 0px 15px 40px rgba(#000, 0.6);
    border-radius: 20px;
    padding: 1em 1.5em;

    .swal2-html-container {
      // margin: 0 1em 2em;
      font-size: 16px;
    }

    .swal2-actions {
      margin: 1.5em 0 0;
    }

    .swal2-title {
      padding-top: 0;
    }

    .swal2-timer-progress-bar-container {
      height: 1em;
      border-radius: 0 0 1em 1em;
      overflow: hidden;
      display: flex;

      .swal2-timer-progress-bar {
        margin-top: auto;
        background-color: $purple-4;
        // border-radius: 0 0 1em 1em;
      }
    }
  }
}

// **********************************************************************
// Padrões de Tabs
// **********************************************************************

.nav {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;

  .nav-item {
    flex-grow: 1;
  }

  .nav-link {
    width: 100%;
    text-align: center;
    font-family: $font-rounded;
    font-weight: bold;
    padding: 15px;
    color: $gray-3;
    font-size: 18px;
    border: none;
    border-bottom: 2px solid $gray-4;
  }

  .nav-link.active {
    border: none;
    color: $purple-3;
    border-bottom: 2px solid $purple-3;
  }
}

// **********************************************************************
// Padrões de Tooltip
// **********************************************************************

.invalid-tooltip {
  background-color: #ffff;
  border: 2px solid $red-2;
  color: $red-2;
}

// **********************************************************************
// CircleCheck
// **********************************************************************
.check {
  display: flex;
  align-items: center;
  justify-content: center;
  // padding-top:2px;
  border-radius: 100%;
  background-color: $gray-3;
  border: none;
  width: 20px;
  height: 20px;
  font-size: 16px;

  &.checked {
    background-color: $green-6 !important;
  }

  &::before {
    content: "";
    width: 10px;
    height: 6px;
    border-bottom: 3px solid #fff;
    border-left: 3px solid #fff;
    transform: rotate(-45deg);
    margin-bottom: 2px;
  }
}

// **********************************************************************
// Padrões de Modal
// **********************************************************************

.modal {

  &.modal-content-pdf {
    .modal-content {
      padding: 0 !important;
      width: 0 !important;
      border: none !important;
    }
  }


  .modal-fullscreen {
    .modal-content {
      border-radius: 0;
    }
  }
}

// Padrão de classe pra usar nos modais bootstrap
@for $i from 1 through 100 {
  .modal-#{$i}w {
    max-width: #{$i}#{"%"} !important;
  }
}

// **********************************************************************
// Padrões outros elementos
// **********************************************************************
hr.dashed {
  border: 1px dashed $gray-4;
  margin: 1em 0;
  background-color: transparent;
}

// **********************************************************************
// Simple Tooltip
// **********************************************************************
[tooltip]:not([tooltip=""]):hover {
  &::before {
    content: attr(tooltip);
    position: absolute;
    top: 100%;
    display: block;
    z-index: 1080;
    margin: 0;
    font-size: 0.875rem;
    // max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
    white-space: nowrap;
    transform: translateY(8px);
    opacity: 0.9;
  }

  &::after {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
    bottom: -1px;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
    transform: translateY(8px);
  }
}

// **********************************************************************
// <Select> MultiSelect
// **********************************************************************

.css-1rhbuit-multiValue {
  background-color: $purple-3 !important;

  .css-12jo7m5,
  .css-xb97g8 {
    color: white !important;
  }
}

// .new-modal-fz {
//   .modal-content {
//     border-radius: 20px;
//     overflow: inherit;
//   }

//   .modal-header {
//     background: white !important;
//     border-bottom: 3px dashed $gray-5;
//     color: $purple-1;
//     font-family: $font-rounded;
//     padding-bottom: 0px;
//     padding-top: 15px;

//     button {
//       background-color: white !important;
//       border: none;
//       font-family: "Comic Sans MS" !important;
//       font-weight: bold;
//       color: $gray-2;
//     }

//     h6 {
//       font-size: 22px;
//       font-weight: bold;
//     }
//   }
// }



// **********************************************************************
// Misc
// **********************************************************************
.no-select,
.user-select-none {
  user-select: none !important;
}

.react-pdf__Page {
  height: 100% !important;
}

.react-pdf__Document {
  height: 1050px;
}

.react-select__menu-list {
  background: red !important;
}

.bg-vip {
  background-color: $pink-1;
}

.bg-habitual {
  background-color: $purple-2;
}

.bg-esporadico {
  background-color: $purple-3;
}

.bg-umavisita {
  background-color: $purple-4;
}

.bg-ausente {
  background-color: $yellow-2;
}

.bg-emrisco {
  background-color: $red;
}

.bg-perdido {
  background-color: $purple-1 !important;
}

.bg-semcompras {
  background-color: $gray-2;
}

.bg-regular {
  background-color: $pink-2;
}

.bg-recuperado {
  background-color: $pink-1;
}

.bg-ativa {
  background-color: $green;
  color: $white;
}

.bg-desativada {
  background-color: $gray-5;
  color: $gray-2;
}

.bg-rascunho {
  background-color: rgba(244, 230, 251, 1);
  color: rgba(147, 0, 217, 1);
}

// **********************************************************************
// Sticky
// **********************************************************************
.sticky {
  position: sticky;
  top: 64px;
  z-index: 10;
}

// **********************************************************************
// Swiper
// **********************************************************************
.swiper {
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: $purple-3;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: $purple-3;
    font-weight: bolder;

    &:hover {
      color: $pink;
    }

    &.swiper-button-disabled {
      opacity: 0.2;
    }
  }
}

// **********************************************************************
// table
// **********************************************************************

// Migrado pra custom/table

.table-responsive {
  .table {
    min-width: 520px;
  }
}

// **********************************************************************
// Form Icon
// **********************************************************************
.form-group {
  position: relative;

  .form-icon {
    position: absolute;
    width: 32px;
    min-height: 38px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    pointer-events: none;
    left: 0;
    top: 0;
    line-height: 1;
    z-index: 5;

    &+.form-control,
    &+.input-group>.form-control {
      padding-left: 28px;
    }
  }

  .form-control {
    min-height: 38px;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

// **********************************************************************
// MISC
// **********************************************************************
.tag {
  display: inline-block;
  padding: 0 4px;
  border-radius: 4px;
  font-size: 11px;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.75rem;
}

.intercom-namespace .intercom-messenger-frame+[class^="intercom-"] {
  z-index: 3000 !important;
}

//Cabeçalho de opções do FzEditor que tem zIndex elevado e fica na frente de tudo
.tox-editor-header {
  z-index: 0 !important;
}

fieldset.fieldset {
  border: 1px solid $gray-4;
  // border-radius: $border-radius;
  padding: 0 0.5rem;

  legend {
    float: none;
    width: auto;
    padding: 0 0.5rem;
    font-size: 13px;
    line-height: 1;
    margin: 0;
  }
}

.link {
  color: $purple-3;
  border: none;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
  }
}