// **********************************************************************
// Dropdown + <Dropdown.Menu/> Reset
// **********************************************************************
.list-group {
  background-color: #fff;
  box-shadow: var(--list-group-box-shadow);
  border: var(--list-group-border);
  border-radius: var(--list-group-border-radius);
  overflow: hidden;

  .list-group-item {
    border-radius: 0;
    border: none !important;
    padding: var(--spacing-2);
    min-height: var(--list-group-item-min-height);
    display: flex;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    color: var(--color-secondary);
    font-weight: 600;
    margin: 0 !important;
    

    
    &:hover {
      background-color: var(--color-purple-50);
    }

    .icon {
      width: 22px;
      flex: 0 0 22px;
      text-align: center;
      margin-right: 6px;
      margin-left: -6px;
    }

    &:active,
    &.active {
      background-color: var(--color-purple-500) !important;
      color: #fff !important;
      outline: none;
    }

    &.selected {
      background-color: var(--color-purple-default) !important;
      color: #fff !important;

    }

    button {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
    }
  }

}
