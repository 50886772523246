// **********************************************************************
// Constant
// **********************************************************************

@keyframes pulse {
  0% {
    transform: scale(0.55);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.55);
  }
}

// **********************************************************************
// OneShot
// **********************************************************************

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

// **********************************************************************
// FadeIn
// **********************************************************************
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

// **********************************************************************
// Bounce
// **********************************************************************
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }

  50% {
    opacity: 0.9;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  80% {
    opacity: 1;
    -webkit-transform: scale(0.89);
    transform: scale(0.89);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1) translate3d(0, 0, 0);
    transform: scale(1) translate3d(0, 0, 0);
  }
}

@keyframes bounceOut {
  0% {
    opacity: 1;
    -webkit-transform: scale(1) translate3d(0, 0, 0);
    transform: scale(1) translate3d(0, 0, 0);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(0.89);
    transform: scale(0.89);
  }
  80% {
    opacity: 0.9;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }  
}




// **********************************************************************
// FadeInDown
// **********************************************************************
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeOutDown {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  
  to {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }
}

// **********************************************************************
// FadeInUp
// **********************************************************************
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  
  to {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }
}

// **********************************************************************
// Engine
// **********************************************************************

.animate {
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;

  &.shake {
    animation-name: shake;
    animation-duration: 0.5s;
  }

  &.pulse {
    transform: scale(0.55);
    animation-name: pulse;
    animation-duration: 0.9s;
    animation-iteration-count: infinite;
  }

  &.fadeIn {
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 0.5s;
  }
  &.fadeIn {
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 0.5s;
  }

  &.bounceIn {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
    animation-name: bounceIn;
    animation-duration: 0.5s;
  }
  &.bounceOut {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
    animation-name: bounceOut;
    animation-duration: 0.5s;
  }


  &.fadeInDown {
    opacity: 0;
    animation-name: fadeInDown;
    animation-duration: 0.3s;
  }
  &.fadeOutDown {
    opacity: 0;
    animation-name: fadeOutDown;
    animation-duration: 0.3s;
  }

  &.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    animation-duration: 0.3s;
  }
  &.fadeOutUp {
    opacity: 0;
    animation-name: fadeOutUp;
    animation-duration: 0.3s;
  }

  &.reverse {
    animation-direction: reverse;
  }
}