.modal-backdrop.show {
  background-color: var(--backdrop-color);
  opacity: var(--backdrop-opacity);
  z-index: var(--z-index-modal-backdrop);
}

.modal {
  z-index: var(--z-index-modal);

  .modal-dialog {}

  .modal-content {
    box-shadow: var(--box-shadow-100);
    padding: 0;
    border-radius: var(--border-radius-xs);
    background-color: transparent;

  }

  .modal-header {
    background-color: var(--color-gray-40);
    border-bottom: 1px solid var(--color-gray-50);
    line-height: 1;
    min-height: 62px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: var(--spacing-3);
    border-top-left-radius: var(--border-radius-xs);
    border-top-right-radius: var(--border-radius-xs);

    .modal-title {
      color: #000;
      font-weight: bold;
      font-size: 20px;
      margin-right: auto;
    }

    .btn-close {
      width: 42px;
      height: 42px;
      border-radius: 100%;
      background: none;
      background-color: var(--color-gray-50);
      padding: 0;
      margin: 0;
      margin-left: 15px;
      opacity: 0.5;
      color: var(--color-gray-90);
      transition: color 0.1s, box-shadow 0.1s;

      &:hover {
        color: var(--color-black);
      }

      &::before {
        content: '×';
        font-size: 32px;
        font-weight: 700;
        font-family: var(--font-family-base);
      }
    }

    &:last-child {
      border-bottom: none;
      border-bottom-left-radius: var(--border-radius-xs);
      border-bottom-right-radius: var(--border-radius-xs);
    }
  }

  .modal-body {
    border-bottom: none;
    padding: var(--spacing-4) var(--spacing-3);
    min-height: 72px;
    border: none;
    background-color: var(--color-white);

    &:first-child {
      border-top-left-radius: var(--border-radius-xs);
      border-top-right-radius: var(--border-radius-xs);
    }

    &:last-child {
      border-bottom-left-radius: var(--border-radius-xs);
      border-bottom-right-radius: var(--border-radius-xs);
    }
  }


  .modal-footer {
    border-top: 1px solid var(--color-gray-50);
    padding: 0;
    margin: 0;
    padding: var(--spacing-2) var(--spacing-3);
    border-radius: 0 0 var(--border-radius-xs) var(--border-radius-xs);
    background-color: var(--color-white);

    &:first-child {
      border-top: none;
      border-top-left-radius: var(--border-radius-xs);
      border-top-right-radius: var(--border-radius-xs);
    }

    &:last-child {
      border-bottom-left-radius: var(--border-radius-xs);
      border-bottom-right-radius: var(--border-radius-xs);
    }
  }

  &.flat {

    .modal-header,
    .modal-body,
    .modal-footer {
      border-color: var(--color-gray-40);
      background-color: var(--color-gray-40);
    }

    .modal-body:not(:first-child) {
      padding-top: 0;
    }

    .modal-body:not(:last-child) {
      padding-bottom: 0;
    }


  }
}