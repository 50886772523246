// **********************************************************************
// Dropdown + <Dropdown.Menu/> Reset
// **********************************************************************
.dropdown {
  .dropdown-menu {
    background-color: #fff;

    .dropdown-header {
      background-color: rgba(#000,0.035);
      font-size: 13px;
    }

    .dropdown-divider {
      margin: 0;
      border-top: 1px solid $gray-4;
    }

    .dropdown-item {
      border-radius: 0.5rem;
      height: 28px;
      display: flex;
      align-items: center;
      background-color: transparent;
      cursor: pointer;
      color: $purple-3;

      &:hover {
        background-color: $gray-5;
      }

      .icon {
        width: 22px;
        flex: 0 0 22px;
        text-align: center;
        margin-right: 6px;
        margin-left: -6px;
      }

      &:active,
      .active {
        background-color: #e9ecef !important;
        box-shadow: inset 0px 4px 4px rgba(0,0,0,0.25), inset 0 0 0 64px rgba(0,0,0,0.05);
        outline: none;
      }

      &:disabled, &.disabled {
        color: $gray !important;
        > * {
          color: $gray !important;
        }
      }

      button {
        background: none;
        border: none;
        padding: 0;
        margin: 0;
      }
    }

  }

  .dropdown-toggle {
    height: 38px;
    min-width: 38px;
    border: none;
    text-align: center;
    background-color: transparent;
    border-radius: 8px;
    color: $gray-2;
    padding: 0;
    min-height: auto;
    &.dropdown-toggle-ellipsis {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    cursor: pointer;
    i[class*="fa"] {
      font-size: 20px;
    }
    &:not(.show-arrow)::after {
      display: none;
    }
  
    &.rounded {
      border-radius: 100%;
    }
  
    &:hover {
      background-color: $gray-5;
      color: $gray-2;
      box-shadow: none;
    }
  }

  
  &.show {
    .dropdown-toggle-ellipsis {
      background-color: $purple-3;
      color: #fff;
    }
  }
  
}

