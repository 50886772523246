.tag:not(.token) {
  display: inline-flex;
  align-items: center;
  justify-content: left;
  height: 20px;
  padding: 0 var(--spacing-2);
  position: relative;
  border-radius: var(--border-radius-xs);
  line-height: 1;
  min-width: var(--input-height);
  white-space: nowrap;
  background-color: var(--color-link-purple-default);
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  z-index: 0;
  cursor: default;
  &:last-of-type {
    margin-right: 0;
  }

  &.tag-xs {
    height: 13px;
    padding: 0 var(--spacing-1);
  }

  &:has(button) {
    padding-right: 0 !important;
  }

  button {
    border: none;
    background-color: transparent;
    // color: #fff;
    color: inherit;
    padding: 0;
    margin: 0;
    cursor: pointer;
    height: 22px;
    width: 22px;
    border-radius: var(--border-radius-xs);
    font-size: 12px;
    margin-left: var(--spacing-1);
    transition: box-shadow 0.2s;
    &:hover {
      box-shadow: inset 0 0 0 20px rgba(#000,0.5);
    }
  }


  &.primary,
  &.tag-primary {
    background-color: var(--color-link-purple-default);
    color: #fff;
  }

  &.secondary,
  &.tag-secondary {
    background-color: var(--color-gray-40);
    color: #000;

  }

  &.danger,
  &.tag-danger {
    background-color: var(--color-danger-default);
    color: #fff;
  }

  &.warning,
  &.tag-warning {
    background-color: var(--color-warning-default);
    color: #fff;

  }

  &.info,
  &.tag-info {
    background-color: var(--color-info-default);
    color: #fff;
  }

  &.link,
  &.tag-link {
    background-color: transparent;
    color: var(--color-link-blue-default);
    text-decoration: none;
  }

  &.disabled,
  &.tag-disabled {
    pointer-events: none !important;
    background-color: var(--color-gray-100) !important;
    color: white !important;
  }

  .icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 22px;
    height: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: var(--color-danger-default);
    }
  }
}