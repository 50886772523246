@media screen and (max-width: 991px) {
  
  /* Isso no mobile deixa tudo apertado demais! */
  /* px-0 px-lg-5 u_u */
  .px-5 {
    padding-left:0px !important;
    padding-right: 0px !important;
  }

  .hide-mobile {
    display: none;
  }

  .campanha-config-responsive {
    display: flex;
    flex-direction: column;

    div {
      button {
        width: 100%;
        margin-top:10px;
        margin-bottom:10px;
      }
    }
  }

  .table-responsize {
    overflow: scroll !important;
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;

    .nav-item {
      width: 100%;
    }
  }

  .intercom-lightweight-app {
    z-index: 2000 !important;
  }

  .modal {
    z-index:2500 !important;
  }

}