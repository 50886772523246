.timeline-item {
    border-left:1px solid #e5e5e5;
    position:relative;
    padding: 1rem 1.5rem .5rem 2.5rem;
    font-size:.9rem;
    min-height:5rem
   }
   .timeline-item:last-child {
    padding-bottom:4rem
   }
   .timeline-item .timeline-date {
    margin-bottom:.5rem
   }
   .timeline-item .timeline-date span {
    color:#888;
    font-size:85%;
    padding-left:.4rem
   }
   .timeline-item .timeline-content {
    padding:.5rem .8rem;
    background-color:white;
    border-radius:.5rem;
    text-align: left;
   }
   .timeline-item .timeline-content span {
    display:block;
    color:#888;
    font-size:85%
   }
   .timeline-item .timeline-icon {
    line-height:2.6rem;
    position:absolute;
    left:-1.3rem;
    width:2.6rem;
    height:2.6rem;
    border-radius:50%;
    font-size:1.1rem;
    color:#fff
   }