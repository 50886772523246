// Colors
@each $name, $color in $colors {
  .text-color-#{$name} {
    color: var(--color-#{$name});
  }

  .bg-color-#{$name},
  .background-color-#{name} {
    background-color: var(--color-#{$name});
  }
}

// Font-Family
@each $name, $font in $font-family {
  .font-#{$name} {
    font-family: var(--font-family-#{$name});
  }
}

// Font-Size
@each $name, $size in $font-size {
  .font-size-#{$name} {
    font-size: var(--font-size-#{$name});
  }
}

// Display
@each $name, $display in $display {
  .#{$name} {
    font-size: var(--#{$name});
  }
}

// Heading
@each $name, $size in $heading {
  .#{$name} {
    font-size: var(--#{$name});
  }

  .h#{$name} {
    font-size: var(--#{$name});
  }
}

// Font-Weight
@each $name, $weight in $font-weight {
  .font-weight-#{$name} {
    font-weight: var(--font-weight-#{$name});
  }
}

// Line-Height
@each $name, $height in $line-height {
  .line-height-#{$name} {
    line-height: var(--line-height-#{$name});
  }
}

// Letter-Spacing
@each $name, $spacing in $letter-spacing {
  .letter-spacing-#{$name} {
    letter-spacing: var(--letter-spacing-#{$name});
  }
}

// Opacity
@each $name, $opacity in $opacity {
  .opacity-#{$name} {
    opacity: var(--opacity-#{$name});
  }

}

// Border Radius
@each $name, $radius in $border-radius {
  .border-radius-#{$name} {
    border-radius: var(--border-radius-#{$name});
  }

  .border-radius-#{$name} {
    border-radius: var(--border-radius-#{$name});
  }

  .border-radius-top-#{$name} {
    border-top-left-radius: var(--border-radius-#{$name});
    border-top-right-radius: var(--border-radius-#{$name});
  }

  .border-radius-right-#{$name} {
    border-top-right-radius: var(--border-radius-#{$name});
    border-bottom-right-radius: var(--border-radius-#{$name});
  }

  .border-radius-bottom-#{$name} {
    border-bottom-right-radius: var(--border-radius-#{$name});
    border-bottom-left-radius: var(--border-radius-#{$name});
  }

  .border-radius-left-#{$name} {
    border-top-left-radius: var(--border-radius-#{$name});
    border-bottom-left-radius: var(--border-radius-#{$name});
  }

  .border-radius-top-left-#{$name} {
    border-top-left-radius: var(--border-radius-#{$name});
  }

  .border-radius-top-right-#{$name} {
    border-top-right-radius: var(--border-radius-#{$name});
  }

  .border-radius-br-#{$name} {
    border-bottom-right-radius: var(--border-radius-#{$name});
  }

  .border-radius-bl-#{$name} {
    border-bottom-left-radius: var(--border-radius-#{$name});
  }

}

// Spacing
@each $name, $spacing in $spacing {
  .padding-#{$name} {
    padding: var(--spacing-#{$name});
  }

  .padding-x-#{$name} {
    padding-left: var(--spacing-#{$name});
    padding-right: var(--spacing-#{$name});
  }

  .padding-y-#{$name} {
    padding-top: var(--spacing-#{$name});
    padding-bottom: var(--spacing-#{$name});
  }

  .padding-top-#{$name} {
    padding-top: var(--spacing-#{$name});
  }

  .padding-right-#{$name} {
    padding-right: var(--spacing-#{$name});
  }

  .padding-bottom-#{$name} {
    padding-bottom: var(--spacing-#{$name});
  }

  .padding-left-#{$name} {
    padding-left: var(--spacing-#{$name});
  }

  .margin-#{$name} {
    margin: var(--spacing-#{$name});
  }

  .margin-x-#{$name} {
    margin-left: var(--spacing-#{$name});
    margin-right: var(--spacing-#{$name});
  }

  .margin-y-#{$name} {
    margin-top: var(--spacing-#{$name});
    margin-bottom: var(--spacing-#{$name});
  }

  .margin-top-#{$name} {
    margin-top: var(--spacing-#{$name});
  }

  .margin-right-#{$name} {
    margin-right: var(--spacing-#{$name});
  }

  .margin-bottom-#{$name} {
    margin-bottom: var(--spacing-#{$name});
  }

  .margin-left-#{$name} {
    margin-left: var(--spacing-#{$name});
  }

  .gap-#{$name} {
    gap: var(--spacing-#{$name});
  }

  .gap-x-#{$name} {
    column-gap: var(--spacing-#{$name});
  }

  .gap-y-#{$name} {
    row-gap: var(--spacing-#{$name});
  }

}

// Box-Shadow
@each $name, $shadow in $box-shadow {
  .box-shadow-#{$name} {
    box-shadow: var(--box-shadow-#{$name});
  }
}

// Pointer-Events
@each $name, $event in $pointer-events {
  .pointer-events-#{$name} {
    pointer-events: var(--pointer-events-#{$name});
  }
}

// Cursor
@each $name, $cursor in $cursor {
  .cursor-#{$name} {
    cursor: var(--cursor-#{$name});
  }
}