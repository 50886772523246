@import '@Styles/variables';

.overlayLoading {
  width: 100vw;
  height: 100vh;
  background-color: rgba(229, 230, 231, 0.9);
  position: fixed;
  top: 0;
  z-index: 1010;

  display: grid;
  place-content: center;

  color: $purple-2;
  backdrop-filter: blur(3px);
  text-align: center;

  .logo {
    svg {
      path {
        fill: $purple-1;
      }
    }

  }

}