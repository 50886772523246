

html, body {
  scroll-behavior: smooth;
  font-feature-settings: 'pnum' 1, 'lnum' 1;
  font-smooth: always;
  font-smooth: 2em;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe {
  border: 0;
}




a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

* {
  box-sizing: border-box;
}