.pdf-view {
  // margin: 0 auto;
  width: 21cm;
  min-height: 28cm;
  background-color: #fff;
  position: relative;
  font-size: 12px;
  font-family: sans-serif;
  overflow: visible;
  position: relative;

  // Apenas para página de impressão
  &.with-bg {
    box-shadow: 0 0 8px 2px rgba(#000, 0.2), 0 0 0 1920px $gray-4;
  }

  .d-block {
    break-inside: avoid-page;
    break-after: avoid-page;
    break-before: avoid-page;
  }
}

.pdf-logo {
  width: 76px;
  margin: 8px 8px 0 0;
  * {fill: #fff !important}
}


.abnt {

  .mt {
    margin-top: 1.25cm;
  }

  .ms,
  .ml {
    margin-left: 1.25cm;
  }

  .me,
  .mr {
    margin-right: 1cm;
  }

  .spacer, .white-space, .spacing {
    display: block;
    width: 100%;
    height: 1px;
    margin: 0.5cm 0;
    break-inside: avoid-page;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.25;
    margin: 0;
    margin-bottom: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    font-family: $font-rounded;
  }

  p,
  ul,
  ol {
    font-size: 12px;
    font-family: $font-default;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
  }

  h4,
  h5,
  h6 {
    font-size: 14px;
  }

  hr {
    margin: 0.15cm 0;
    &.dashed {
      margin: 0.15cm 0;
      border: none;
      border-top: 1px dashed $gray-2;
    }
  }
  .pagebreak, .page-break {
    border-top: 4px double $gray-3;
    margin: 1.25cm 0;
    position: relative;
    &::before {
      content: 'Quebra';
      position: absolute;
      left: 0;
      right: 0;
      top: -13px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 22px;
      width: 80px;
      font-size: 10px;
      color: $gray-3;
      background-color: #fff;
    }
  }
}

@media print {
  .pdf-view {
    box-shadow: none !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      page-break-after: avoid;
    }

    table,
    figure {

      page-break-inside: avoid;

    }

    .pagebreak, .page-break {
      page-break-after: always;
      margin: 0;
      border: none;
      &::before {
        display: none;

      }
    }
  }
}