h1,
h2,
h3,
h4,
h5,
h6 {
  &.section-title {
    font-weight: bold;
    color: $purple-1;
    margin-bottom: 1em;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.headline {
    font-weight: bold;
    color: $purple-1;
    line-height: 1.5;
    margin-bottom: 1em;
  }
}

h1.headline,
.h1.headline {
  font-size: 60px;
  letter-spacing: -1.5px;
}
h2.headline,
.h2.headline {
  font-size: 48px;
  letter-spacing: -0.5px;
}
h3.headline,
.h3.headline {
  font-size: 34px;
  letter-spacing: 0;
}
h4.headline,
.h4.headline {
  font-size: 24px;
  letter-spacing: 0;
}
h5.headline,
.h5.headline {
  font-size: 20px;
  letter-spacing: 0;
}
h6.headline,
.h6.headline {
  font-size: 16px;
  letter-spacing: 0.15px;
}

.text-underline {
  text-decoration: underline;
}

.text-stroke,
.text-line-through {
  text-decoration: line-through;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-black {
  font-weight: bolder;
}

.font-weight-light {
  font-weight: lighter;
}

.font-weight-normal {
  font-weight: normal;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-nowrap {
  white-space: nowrap;
}

.font-default,
.text-default {
  font-family: $font-default;
}

.font-rounded,
.text-rounded {
  font-family: $font-rounded;
}

// #########################################################
// font-size
// #########################################################
@for $i from 6 through 50 {
  .fs-#{$i} {
    font-size: #{$i}px !important;
  }
}

// #########################################################
// font-weight
// #########################################################
@for $i from 1 through 9 {
  .fw-#{$i * 100} {
    font-weight: #{$i * 100} !important;
  }
}

// #########################################################
// LineHeight
// #########################################################
.lh-50 {
  line-height: 0.5 !important;
}
.lh-100 {
  line-height: 1 !important;
}
.lh-105 {
  line-height: 1.05 !important;
}
.lh-110 {
  line-height: 1.1 !important;
}
.lh-115 {
  line-height: 1.15 !important;
}
.lh-120 {
  line-height: 1.2 !important;
}
.lh-125 {
  line-height: 1.25 !important;
}
.lh-150 {
  line-height: 1.5 !important;
}
.lh-175 {
  line-height: 1.75 !important;
}
.lh-200 {
  line-height: 2 !important;
}

// #########################################################
// overline
// #########################################################
.overline-1 {
  font-family: $font-default;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: $gray-2;
}
