
.comment {
  position: relative;
  border: 1px solid $gray-4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  position: relative;
  min-height: 64px;
  border-radius: 1em;
  // filter: drop-shadow(0 3px 2px rgba(#000,0.1));
  background-color: #fff;
  &.top {
    &::after, &::before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    
    &::after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      border-width: 10px;
      margin-left: -10px;
    }
    &::before {
      border-color: rgba(229, 230, 231, 0);
      border-bottom-color: #E5E6E7;
      border-width: 13px;
      margin-left: -13px;
    }
  }
  &.right {
    &::after, &::before {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    
    &::after {
      border-color: rgba(255, 255, 255, 0);
      border-left-color: #fff;
      border-width: 10px;
      margin-top: -10px;
    }
    &::before {
      border-color: rgba(229, 230, 231, 0);
      border-left-color: #E5E6E7;
      border-width: 13px;
      margin-top: -13px;
    }
  }
  &.bottom {
    &::after, &::before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    
    &::after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #fff;
      border-width: 10px;
      margin-left: -10px;
    }
    &::before {
      border-color: rgba(229, 230, 231, 0);
      border-top-color: #E5E6E7;
      border-width: 13px;
      margin-left: -13px;
    }
  }
  &.left {
    &::after, &::before {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    
    &::after {
      border-color: rgba(255, 255, 255, 0);
      border-right-color: #fff;
      border-width: 10px;
      margin-top: -10px;
    }
    &::before {
      border-color: rgba(229, 230, 231, 0);
      border-right-color: #E5E6E7;
      border-width: 13px;
      margin-top: -13px;
    }
  }
}