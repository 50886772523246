.table {
  vertical-align: middle;
  width: 100%;
  thead {
    tr {
      border: none;
      border-bottom: 1px solid $gray-3;
      color: $purple-1;
      font-size: 13px;
      cursor: default;
      th {
        font-weight: 500;
        position: relative;
        &:has(i[class*="fa-"]) {
          padding-right: 22px;
          i[class*="fa-"]:first-child {
            position: absolute;
            width: 22px;
            right: 0;
            top: 0;
            bottom: 0;
            font-size: 13px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &:hover {
            background-color: $gray-5;
            cursor: pointer;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &.actions {
          white-space: nowrap;
        }
      }
    }
  }

  &.striped {
    tbody {
      tr {
        &:nth-of-type(even) {
          background-color: $gray-5;
        }
      }
    }
  }
  &.hoverable {
    tbody {
      tr {
        &:hover {
          background-color: $purple-5;
        }
      }
    }
  }

  &.sticky-header {
    thead {
      tr {
        position: sticky;
        top: 0;
        background-color: #fff;
      }
    }
  }

}

// TODO: MIGRAR TODOS BOTÕES DE COLUNAS ACTIONS PARA btn-action
// TODO: remover o código abaixo após migração
.table {
  tbody {
    td {
      &.actions {
        vertical-align: middle;
        white-space: nowrap;
        .action {
          min-width: auto;
          padding: 0;
          border: none;
          min-width: 28px;
          height: 32px;
          background-color: transparent;
          opacity: 0.75;
          font-size: 16px;
          &:hover {
            opacity: 1;
          }
          &:disabled, &.disabled {
            pointer-events: none;
            opacity: 1;
            color: $gray-4 !important;
            * {color: $gray-4 !important;}

          }
        }
      }
    }
  }
}
/////////////////////////////////////////////
/// Depreciados, NÃO USAR
/////////////////////////////////////////////

.table-fz {

  &.is-loading {
    opacity: 0.5;
  }

  thead {
    background-color: #EFEFEF;
    color: #646363;

    tr th {
      font-size:13px;
      cursor: pointer;

      i {
        float:right;
        margin-top:3px;
      }

      &:hover {
        background-color: #DADADA;
      }
    }

    tr {
      border-bottom:2px solid $gray-4;
    }
  }

  tbody {
    tr {
      &:nth-child(2n) {
        background-color: $gray-5 !important;
      }
    }

    td {
      color: $gray-2;
      padding:11px;
      vertical-align: middle;
    }
  }
}


.tdiv {
  display: table;
  width: 100%;
  // border: 1px solid $gray-3;
  border-radius: 1em;

  &>.trow {
    display: table-row;
  }


  .trow {

    // width: 100%;
    .tcol {
      display: table-cell;
      padding: .75rem;
      vertical-align: top;
      border-top: 1px solid $gray-4;
      border-left: 1px solid $gray-4;

      &:last-child {
        border-right: 1px solid $gray-4;
      }
    }

    &.thead {
      color: #646363;
      position: sticky;
      top: 0;

      .tcol {
        background-color: #EFEFEF;
        border-bottom-width: 2px;
        vertical-align: bottom;
        font-family: $font-rounded;
        font-weight: 500;
      }
    }

    &:first-child {
      .tcol {
        &:first-child {
          border-radius: 1em 0 0 0;
        }

        &:last-child {
          border-radius: 0 8px 0 0;
        }

        &:first-child:last-child {
          border-radius: 1em 8px 0 0;
        }
      }
    }

    &:last-child {
      .tcol {
        border-bottom: 1px solid $gray-4;

        &:first-child {
          border-radius: 0 0 0 8px;
        }

        &:last-child {
          border-radius: 0 0 8px 0;
        }

        &:first-child:last-child {
          border-radius: 0 0 8px 8px;
        }
      }
    }

    &:first-child:last-child {
      .tcol {
        &:first-child {
          border-radius: 1em 0 0 8px;
        }

        &:last-child {
          border-radius: 0 8px 8px 0;
        }

        &:first-child:last-child {
          border-radius: 1em;
        }
      }
    }
  }
}


// Custom Table para ser usado em div, pois permite contextmenu na linha toda :B
.tcontainer {
  display: block;
  max-height: 480px;
  overflow-y: auto;
  width: 100%;

  .tdiv {
    .trow {
      &:first-child {
        .tcol {
          &:first-child {
            border-radius: 1em 0 0 0;
          }

          &:last-child {
            border-radius: 0;
          }

          &:first-child:last-child {
            border-radius: 1em 0 0 0;
          }
        }
      }

      &:last-child {
        .tcol {
          border-bottom: 1px solid $gray-4;

          &:first-child {
            border-radius: 0;
          }

          &:last-child {
            border-radius: 0;
          }

          &:first-child:last-child {
            border-radius: 0;
          }
        }
      }

      &:first-child:last-child {
        .tcol {
          &:first-child {
            border-radius: 1em 0 0 0;
          }

          &:last-child {
            border-radius: 0 0 0 0;
          }

          &:first-child:last-child {
            border-radius: 1em 0 0 8px;
          }
        }
      }
    }
  }
}

// .pagination-fz {
//   font-family: $font-rounded !important;
//   font-weight: 400;
//   button {
//     background-color: $gray-4;
//     border: 1px solid $gray-3;
//     margin-left:2px;
//     margin-right:2px;
//     font-family: $font-rounded !important;
//     font-weight: 400;
//   }

//   .input-group-text {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background: $gray-4;
//     padding:10px;
//     min-width: 70px;
//   }

//   .form-control {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
// }


