@import "../variables";

div.drop-menu {
  & > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    // column-fill: balance;
    li {
      height: auto;
      font-family: $font-default;
      hr {
        margin: 0;
        border-top: 1px solid $gray-3;
        height: 0;
      }
      &.header {
        background-color: $gray-4;
        color: $gray-1;
        font-weight: bold;
        display: flex;
        height: 32px;
        align-items: center;
        padding: 0 0.75em;
      }
      a, button, .item {
        border: none;
        background-color: transparent;
        line-height: 26px;
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 30px;
        padding-right: 1em;
        color: $gray-1;
        white-space: nowrap;
        width: 100%;
        font-weight: 500;
        font-size: 0.9em;
        & > i.fa, & > i.far, & > i.fas {
          width: 25px;
          background-color: rgba(#000,0.05);
          display: inline-flex;
          height: 100%;
          margin-right: 0.5em;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0;
          top: 0;
          font-size: 0.8em;
        }
        &:hover {
          background-color: $purple-3;
          color: #fff !important;
          i {
            background-color: $purple-3;
            color: #fff !important;
          }
        }
      }
    }
  }

  &.menu-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: $box-shadow;
    user-select: none;
  }
}