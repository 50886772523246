@import "@Styles/variables";

.alert {
  padding: 1em 1.75em 1em 1em;
  border-radius: 0.5em;
  position: relative;
  &.withIcon {
    padding: 1em 1.75em 1em 1em;
  }
  .icon {
    flex: 0 0 40px;
  }
}
.close {
  position: absolute;
  right: -8px;
  top: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 0 0 3px;
  font-size: 18px;
  color: $gray-2;
  mix-blend-mode: multiply;
  &:hover {
    color: #000;
  }
}