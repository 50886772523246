// INPUTS **********************************************************************
.form-control {
  font-size: var(--input-font-size);
  border: 1px solid var(--input-color-border);
  padding: 0 var(--spacing-2);
  transition: 0.5s;
  height: var(--input-height);
  border-radius: var(--border-radius-xs);
  transition: border 0.2s ease-in-out;
  color: var(--input-color-text);
  line-height: 1;

  &:not(:disabled):hover {
    border-color: var(--input-color-hover-border);
  }

  &.xs,
  &.input-xs {
    height: var(--input-height-xs);
    padding: var(--input-padding-xs);
    font-size: var(--input-font-size-xs);
  }

  &.sm,
  &.input-sm {
    height: var(--input-height);
    padding: var(--input-padding);
    font-size: var(--input-font-size);
  }

  &.md,
  &.input-md {
    height: var(--input-height-md);
    padding: var(--input-padding-md);
    font-size: var(--input-font-size-md);
  }

  &.lg,
  &.input-lg {
    height: var(--input-height-lg);
    padding: var(--input-padding-lg);
    font-size: var(--input-font-size-lg);
  }

  // Password
  &[type="password"]:not(:placeholder-shown) {
    letter-spacing: 1px;
    font-size: 22px;
  }

  &[type="password"]:placeholder-shown {
    letter-spacing: normal;
    // font-size: 22px;
  }

  &:disabled,
  &.disabled {
    background-color: var(--input-color-disabled-background);
    color: var(--input-color-disabled-text);
    border-color: var(--input-color-disabled-border);
  }

  &.dropdown-toggle {
    background-color: #fff;
  }

  >span,
  >i {
    margin-top: 2px;
    display: block;
  }

  &:focus,
  &.focus,
  &.show {
    // box-shadow: 0 0 1px 3px var(--color-info-200);
    outline: 3px solid var(--color-info-200);
    border-color: var(--input-color-hover-border) !important;
  }

  &::placeholder {
    font-size: inherit;
    font-family: var(--font-rounded);
    color: var(--input-color-placeholder);
  }

  &+.btn {
    border-radius: 0 var(--border-radius-xs) var(--border-radius-xs) 0;
  }

  &:has(+ .btn) {
    border-radius: var(--border-radius-xs) 0 0 var(--border-radius-xs);
  }

  &.invalid {
    border-color: var(--color-danger);
  }


}

textarea.form-control {
  min-height: 100px;
  height: auto;
  field-sizing: content;
  padding: calc(var(--spacing-2) + 4px) var(--spacing-2) var(--spacing-2);
  line-height: 1.5;

  &.xs,
  &.input-xs {
    height: var(--input-height-xs);
    padding: var(--input-padding-xs);
    font-size: var(--input-font-size-xs);
  }

  &.sm,
  &.input-sm {
    height: var(--input-height);
    padding: var(--input-padding);
    font-size: var(--input-font-size);
  }

  &.md,
  &.input-md {
    height: auto;
    padding: var(--input-padding-md);
    font-size: var(--input-font-size-md);
  }

  &.lg,
  &.input-lg {
    height: auto;
    padding: var(--input-padding-lg);
    font-size: var(--input-font-size-lg);
  }
}

select.form-control {
  appearance: auto;

  option {
    &:disabled {
      background-color: var(--input-color-disabled-background);
      color: var(--input-color-disabled-text);
    }
  }

  optgroup {
    border-top: 2px dotted var(--input-color-border);
  }
}

// GROUPING ********************************************************************
.input-group {
  border-radius: var(--border-radius-xs);
  border: 1px solid var(--input-color-border);
  transition: border 0.2s ease-in-out;

  &:not(:disabled):hover {
    border-color: var(--input-color-hover-border);
  }

  .input-group-text,
  .input-group-button,
  .input-group-icon {
    @extend .input-group-text;
    padding: 0 var(--spacing-3);
    background-color: transparent;
    color: var(--input-group-color-text);
    font-size: var(--input-font-size);
    border: none;
    justify-content: center;

    &:first-child {
      border-radius: var(--border-radius-xs) 0 0 var(--border-radius-xs);
    }

    &:last-child {
      border-radius: 0 var(--border-radius-xs) var(--border-radius-xs) 0;
    }

    // INPUT-BORDER-ADJUSTMENTS
    &+.form-control {
      border-left: none !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:has(+ .form-control.md) {
      font-size: var(--input-font-size-md);
      min-width: var(--input-height-md);
    }

    &:has(+ .form-control.lg) {
      font-size: var(--input-font-size-lg);
      min-width: var(--input-height-lg);
    }
  }

  .input-group-icon,
  .input-group-button {
    // background-color: #fff;

    &:disabled {
      background-color: var(--input-color-disabled-background);
      color: var(--input-color-disabled-text);
    }
  }

  .input-group-button {
    &:disabled {
      color: var(--input-color-disabled-background);
    }
  }

  .input-group-button {
    transition: color .2s ease-in-out;

    &:hover {
      color: #000;
    }
  }

  // INPUT-BORDER-ADJUSTMENTS
  .form-control {
    border: none;
    outline: none;
    // height: calc(var(--input-height) - 2px);

    &:has(+ .input-group-text),
    &:has(+ .input-group-icon),
    &:has(+ .input-group-button) {
      border-right: none !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:has(+ .input-group-icon),
    &:has(+ .input-group-button) {

      .input-group-icon,
      .input-group-button {
        border-left: none !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.md,
    &.input-md {

      +.input-group-text,
      +.input-group-icon,
      +.input-group-button {
        font-size: var(--input-font-size-md);
        min-width: var(--input-height-md);
      }
    }

    &.lg,
    &.input-lg {

      +.input-group-text,
      +.input-group-icon,
      +.input-group-button {

        .input-group-text,
        .input-group-icon,
        .input-group-button {
          font-size: var(--input-font-size-lg);
          min-width: var(--input-height-lg);
        }
      }
    }
  }

  &:has(.form-control:focus),
  &:has(.form-control.focus),
  &:has(.form-control.show) {
    outline: 3px solid var(--color-info-200);
    // border-color: var(--input-color-hover-border) !important;
  }

  &:has(.form-control.invalid) {
    border-color: var(--color-danger);
  }

  &:has(.form-control.xs),
  &:has(.form-control.input-xs) {
    .form-control {
      height: var(--input-height-xs);
      padding: var(--input-padding-xs);
      font-size: var(--input-font-size-xs);
    }
  }

  &:has(.form-control.sm),
  &:has(.form-control.input-sm) {
    .form-control {
      height: var(--input-height);
      padding: var(--input-padding);
      font-size: var(--input-font-size);
    }
  }

  &:has(.form-control.md),
  &:has(.form-control.input-md) {
    .form-control {
      height: var(--input-height-md);
      padding: var(--input-padding-md);
      font-size: var(--input-font-size-md);
    }
  }

  &:has(.form-control.lg),
  &:has(.form-control.input-lg) {
    .form-control {
      height: var(--input-height-lg);
      padding: var(--input-padding-lg);
      font-size: var(--input-font-size-lg);
    }
  }



}

// FORM RANGE ******************************************************************
.form-range::-webkit-slider-thumb {
  background-color: var(--color-primary);
  border-radius: var(--border-radius-xs);
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  background-color: var(--bs-secondary-bg);
}

.form-range::-moz-range-thumb {
  background-color: #0d6efd;
}

// .form-range::-moz-range-thumb:active {
//   background-color: #b6d4fe;
// }
// .form-range::-moz-range-track {
//   background-color: var(--bs-secondary-bg);
// }
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

// **********************************************************************
// React Select
// **********************************************************************
.react-select {
  .react-select__control {
    border: 1px solid var(--input-color-border);
    min-height: var(--input-height);
    color: var(--input-color-text);
    border-radius: var(--border-radius-xs);
    transition: border 0.2s ease-in-out;

    .react-select__value-container {
      padding: 0 var(--spacing-2);
    }
  }

  &:hover {
    .react-select__control {
      border-color: var(--input-color-hover-border);
    }
  }

  &.react-select--is-disabled {
    .react-select__control {
      background-color: var(--input-color-disabled-border);
      color: var(--input-color-disabled-text);
    }

    &:hover {
      .react-select__control {
        border-color: var(--input-color-disabled-border);
      }
    }
  }
}

// **********************************************************************
// Form Icon @Deprecated
// **********************************************************************
.form-group {
  position: relative;

  label {
    font-weight: bold;
  }

  .form-icon {
    position: absolute;
    width: 32px;
    height: 38px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    left: 0;
    top: 0;
    line-height: 1;
    font-size: 12px;

    &+.form-control,
    &+.input-group>.form-control {
      padding-left: 28px;
    }
  }

  .form-control {
    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}