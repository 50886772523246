.card {
  background-color: white;
  box-shadow: 0px 4px 16px rgba(71, 94, 127, 0.12);
}

.panel, .wrapper-body, .section-wrapper, .content-wrapper {
  width: 100%;
  display: block;
  background: #ffffff;
  // border: 1px solid $gray-4;
  box-sizing: border-box;
  padding: 0.75em;
  // border-radius: 4px;
}

.box {
  background: #FFFFFF;
  border-radius: 1em;
  padding: 0.75em;
  &.shadow {
    box-shadow: $box-shadow;
  }
}
.shadow-box {
  background: #FFFFFF;
  box-shadow: $box-shadow;
  border-radius: 1em;
  padding: 0.75em;
}

.rounded-box, .border-box {
  border: 1px solid $gray-4;
  border-radius: 1em;
  padding: 0.75em;
}

.rounded-box, .border-box {
  border: 1px solid $gray-4;
  border-radius: 1em;
  padding: 0.75em;
}



.grey-box {
  background: #f9f9f9;
  border: 1px solid $gray-4;
  border-radius: 1em;
  padding: 0.75em;
  &.hoverable {
    &:hover {
      color: $purple-3;
    }
  }
}