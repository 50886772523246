:root {
  // Colors
  @each $name, $color in $colors {
    --color-#{$name}: #{$color};
  }

  // Font-Family
  @each $name, $font in $font-family {
    --font-family-#{$name}: #{$font};
  }

  // Font-Size
  @each $name, $size in $font-size {
    --font-size-#{$name}: #{$size};
  }

  // Display
  @each $name, $display in $display {
    --#{$name}: #{$display};
  }

  // Heading
  @each $name, $size in $heading {
    --#{$name}: #{$size};
  }

  // Font-Weight
  @each $name, $weight in $font-weight {
    --font-weight-#{$name}: #{$weight};
  }

  // Line-Height
  @each $name, $height in $line-height {
    --line-height-#{$name}: #{$height};
  }

  // Letter-Spacing
  @each $name, $spacing in $letter-spacing {
    --letter-spacing-#{$name}: #{$spacing};
  }

  // Opacity
  @each $name, $opacity in $opacity {
    --opacity-#{$name}: #{$opacity};
  }

  // Border Radius
  @each $name, $radius in $border-radius {
    --border-radius-#{$name}: #{$radius};
  }

  // Spacing
  @each $name, $spacing in $spacing {
    --spacing-#{$name}: #{$spacing};
  }

  // Box-Shadow
  @each $name, $shadow in $box-shadow {
    --box-shadow-#{$name}: #{$shadow};
  }

  // Pointer-Events
  @each $name, $event in $pointer-events {
    --pointer-events-#{$name}: #{$event};
  }

  // Cursor
  @each $name, $cursor in $cursor {
    --cursor-#{$name}: #{$cursor};
  }

  // Backdrop
  @each $name, $value in $backdrop {
    --backdrop-#{$name}: #{$value};
  }

  // Input (Form Control)
  @each $name, $value in $input {
    --input-#{$name}: #{$value};
  }

  // Buttons
  @each $name, $value in $button {
    --button-#{$name}: #{$value};
  }
  
  // Tooltips
  @each $name, $value in $tooltip {
    --tooltip-#{$name}: #{$value};
  }

  // Popover
  @each $name, $value in $popover {
    --popover-#{$name}: #{$value};
  }

  // List Group
  @each $name, $value in $list-group {
    --list-group-#{$name}: #{$value};
  }

  // Dropdown
  @each $name, $value in $dropdown {
    --dropdown-#{$name}: #{$value};
  }

  // Accordion
  @each $name, $value in $accordion {
    --accordion-#{$name}: #{$value};
  }

  // Z-Index
  @each $name, $value in $z-index {
    --z-index-#{$name}: #{$value};
  }
}
