.offcanvas-backdrop.show {
  background-color: var(--backdrop-color);
  opacity: var(--backdrop-opacity);
  z-index: var(--z-index-offcanvas-backdrop);
}

.offcanvas {
  box-shadow: var(--box-shadow-100);
  z-index: var(--z-index-offcanvas);
  .offcanvas-header {
    background-color: var(--color-gray-40);
    background-color: var(--color-gray-40);
    border-bottom: 1px solid var(--color-gray-50);
    line-height: 1;
    min-height: 62px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: var(--spacing-3);

    .offcanvas-title {
      color: #000;
      font-weight: bold;
      font-size: 20px;
      margin-right: auto;
    }

    .btn-close {
      width: 42px;
      height: 42px;
      border-radius: 100%;
      background: none;
      background-color: var(--color-gray-50);
      padding: 0;
      margin: 0;
      margin-left: 15px;
      opacity: 0.5;
      color: var(--color-gray-90);
      transition: color 0.1s, box-shadow 0.1s;
      &:hover {
        color: var(--color-black);
      }
      &::before {
        content: '×';
        font-size: 32px;
        font-weight: 700;
        font-family: var(--font-family-base);
      }
    }
  }
  .offcanvas-body {
    padding: var(--spacing-4) var(--spacing-3);
  }
}