.form-control {
  font-size: 13px;
  border: 1px solid #e5e6e7;
  transition: 0.5s;
}

.form-control:focus {
  border-color: $green !important;
  box-shadow: 0 0 0 0rem rgba(142, 185, 53, 0.5);
}

.form-control::placeholder {
  color: #b3b3b3;
}
.form-control:-ms-input-placeholder {
  color: #b3b3b3;
}
.form-control::-ms-input-placeholder {
  color: #b3b3b3;
}

.form-control-fz-new {
  height: 50px;
  font-size: 14px;
  padding: 0 8px;
  border-radius: 0.5rem;
  border: solid #bebebe 1px !important;
  border: none;
  box-shadow: 0 0 0 30px white inset;

  &::placeholder {
    font-style: italic;
    letter-spacing: normal;
  }
}

.form-control-fz-new-group {
  .input-group-text{
    margin: 0;
    color: $gray-2;
    border-right: none;
    padding: 0 14px;
    border: 1px solid $gray-3;
    background-color: $gray-5;
  }

  .form-control-fz-new{
    border-left: none !important;
  }
}

.form-check-input {
  cursor: pointer;
}

.form-check {
  label {
    cursor: pointer;
  }
}