@import "../variables.scss";

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  border-radius: 8px;
  width: auto;
  height: auto;
  min-height: 38px;
  border: none;
  user-select: none;
  vertical-align: middle;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  font-family: $font-rounded;
  outline: none;
  position: relative;
  white-space: nowrap;
  line-height: 1;
  &.btn-action {
    font-size: 17px;
    opacity: 0.75;
    padding: 0;
    min-width: 38px;
    // margin-right: 4px;
    background-color: transparent;
    // color: $gray-1;
    color: $purple-1;
    &.dropdown-toggle {
      &::after {
        display: none;
      }
    }
    &:hover,
    &:active,
    &.active {
      opacity: 1;
      box-shadow: inset 0 0 0 38px rgba(#000, 0.05);
    }
    &:active,
    &.active {
      text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.05);
    }
  }
  .flying-icon,
  .float-icon {
    position: absolute;
    right: 15px;
    &.left {
      right: auto;
      left: 15px;
    }
  }
  i.fa,
  i.fas,
  i.far,
  i.fab {
    width: 22px;
    text-align: center;
  }

  &:hover {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25), inset 0 0 0 64px rgba(255, 255, 255, 0.1);
    outline: none;
  }

  &:active,
  &.active {
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0 0 0 64px rgba(0, 0, 0, 0.05);
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &.hover-new {
    &:hover{
      background-color: $purple-new-hover !important;
    }
  }

  &.noShadow {
    box-shadow: none;
  }

  &[disabled],
  &.disabled {
    opacity: 0.55;
    pointer-events: none;
    filter: grayscale(1);
  }
}
.btn-action {
  font-size: 20px;
  opacity: 0.75;
  min-width: 22px;
  margin-right: 4px;
  border: none !important;
  padding: 0 !important;
  background-color: transparent !important;
  &:last-child {
    margin-right: 0;
  }
  &:hover,
  &:active,
  &.active {
    opacity: 1;
    box-shadow: none !important;
  }
  &:active,
  &.active {
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.05);
  }
}

.btn[class*="btn-gradient"],
.btn[class*="btn-bg-"],
.btn[class*="btn-background-"] {
  color: #fff;
  &:hover {
    color: #fff;
  }
}
.btn[class*="btn-border"] {
  background-color: #fff;
  border: 2px solid;
  padding: 6px 15px;
}
/* Botões Degradê */
.btn-gradient-purple {
  background: #834be1;
  background: linear-gradient(339.92deg, #492b7c 16.31%, #834be1 83.69%);
  &:active,
  &.active {
    background: linear-gradient(159.75deg, #2d0e60 16.31%, #8156ca 83.69%);
  }
}
.btn-gradient-pink {
  background: #e14bd8;
  background: linear-gradient(90deg, #e14bdb 0%, #8d0c88 100%);
  &:active,
  &.active {
    background: linear-gradient(159.75deg, #8d0c88 16.31%, #e14bdb 83.69%);
  }
}

.btn-gradient-pink-purple,
.btn-gradient-purple-pink {
  background: #a6009f;
  background: linear-gradient(90deg, #a6009f 0%, #301551 100%);
  &:active,
  &.active {
    background: linear-gradient(90deg, darken(#a6009f, 10%) 0%, darken(#301551, 10%) 100%);
  }
}

.btn-gradient-blue {
  background: #834be1;
  background: linear-gradient(339.92deg, #0281b4 16.31%, #00a1e2 83.69%);
  &:active,
  &.active {
    background: linear-gradient(159.75deg, #0281b4 16.31%, #00a1e2 83.69%);
  }
}

.btn-fz-purple-invert {
  color: #834be1;
  background-color: white;
  border:2px solid #834be1;

  &:hover {
      background-color: #834be1;
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25), inset 0 0 0 64px rgba(255,255,255,0.1);
      color: #fff;
      outline: none;
  }
}

.btn-fz-white-invert {
  color: #ffff;
  background-color: #834be1;
  border: 2px solid #ffff;

  &:hover {
      background-color: #ffff;
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25), inset 0 0 0 64px rgba(255,255,255,0.1);
      color: #834be1;
      outline: none;
  }
}

.btn-fz-red-invert {
  color: #de4b39;
  background-color: white;
  border:1px solid #de4b39;

  &:hover {
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25), inset 0 0 0 64px rgba(255,255,255,0.1);
      color: #fff;
      outline: none;
      background-color: #de4b39;
  }
}

.btn-fz-green-invert {
  color: #81A831;
  background-color: white;
  border:1px solid #81A831;

  &:hover {
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25), inset 0 0 0 64px rgba(255,255,255,0.1);
      color: #fff;
      outline: none;
      background-color: #81A831;
  }
}

.input-group {
  .btn + .btn {
    &::before, &::after {
      content: '';
      width: 1px;
      height: calc(100% - 15px);
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      mix-blend-mode: luminosity;
    }

    &::before {
      background-color: rgba(#fff,0.2);
    }
    &::after {
      left: -1px;
      background-color: rgba(#000,0.2);
    }
  }
}